import { PaymentApi } from "../../services/PaymentApi";

const state = () => ({
    paymentCustomersCaches: []
});

const getters = {
    findPaymentCustomersCache: (state) => key => state.paymentCustomersCaches.find(pcc => pcc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.paymentCustomersCaches.find(pcc => pcc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.paymentCustomersCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setCustomers(state, { key, customers }) {
        const existing = state.paymentCustomersCaches.find(pcc => pcc.key === key);
        if (!!existing) {
            existing.values = customers;
            existing.loading = false;
        } else {
            state.paymentCustomersCaches.push({ key: key, values: customers, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.paymentCustomersCaches.find(pcc => pcc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendCustomer(state, { key, customer }) {
        const existing = state.paymentCustomersCaches.find(pcc => pcc.key === key);
        if (!!existing) {
            existing.values.push(customer);
        } else {
            state.paymentCustomersCaches.push({ key: key, values: [customer], loading: false });
        }
    }
}

const actions = {
    findCustomers({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            PaymentApi.get('Customer?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setCustomers', { key: key, customers: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    addCustomer({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            PaymentApi.post('Customer', payload)
                .then(response => {
                    commit('appendCustomer', { key: key, customer: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
