import { render, staticRenderFns } from "./AppointmentOrderItemSelect.vue?vue&type=template&id=b6609ff6&scoped=true"
import script from "./AppointmentOrderItemSelect.vue?vue&type=script&lang=js"
export * from "./AppointmentOrderItemSelect.vue?vue&type=script&lang=js"
import style0 from "./AppointmentOrderItemSelect.vue?vue&type=style&index=0&id=b6609ff6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6609ff6",
  null
  
)

export default component.exports