import { PaymentCustomersCacheMixin } from "./PaymentCustomersCacheMixin"
import { mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { SiteIdMixin } from "../Shared/Site/SiteIdMixin";

export const PaymentCustomersAddMixin = {
    mixins: [PaymentCustomersCacheMixin, SiteIdMixin],
    data() {
        return {
            addingCustomer: false
        }
    },
    computed: {
        providerTypeId() {
            console.log('OVERRIDE COMPUTED - providerTypeId');
        },
        customerAddModel() {
            return {
                providerTypeId: this.providerTypeId,
                clientId: this.clientId
            };
        },
        customerAddModelValid() {
            const model = this.customerAddModel;
            return !!this.siteId && !!model.clientId && model.providerTypeId > 0;
        },
        customer() {
            return this.customers.length > 0 ? this.customers.find(c => c.providerType.id == this.providerTypeId) : null
        }
    },
    methods: {
        ...mapActions('PaymentCustomers', [
            'addCustomer'
        ]),
        tryAddCustomer() {
            const self = this;
            if (!self.customerAddModelValid || !!self.loadingCustomers || !!self.customer) {
                return;
            }
            self.addingCustomer = true;
            self.error = '';
            self.addCustomer({ key: self.customersCacheKey, payload: self.customerAddModel })
                .catch(error => self.error = ErrorHelper.getError(error))
                .finally(() => self.addingCustomer = false);
        }
    }
}
