import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const CommunicationContentCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('CommunicationContents', [
            'findCommunicationContentCache'
        ]),
        communicationId() {
            console.log('!!!OVERRIDE COMPUTED - communicationId!!!');
        },
        communicationContentCache() {
            return this.findCommunicationContentCache(this.communicationId);
        },
        communicationContent() {
            return !!this.communicationContentCache ? this.communicationContentCache.value : null;
        },
        loadingCommunicationContent() {
            return !!this.communicationContentCache && this.communicationContentCache.loading;
        },
        content() {
            return !!this.communicationContent ? this.communicationContent.content : ''
        }
    },
    watch: {
        communicationId() {
            this.refreshCommunicationContent();
        }
    },
    methods: {
        ...mapActions('CommunicationContents', [
            'getCommunicationContent'
        ]),
        refreshCommunicationContent() {
            if (!this.communicationContentCache || !!this.communicationContentCache.retry) {
                this.tryGetCommunicationContent();
            }
        },
        tryGetCommunicationContent() {
            const self = this;
            if (!self.communicationId) {
                return;
            }
            self.error = '';
            self.getCommunicationContent(self.communicationId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCommunicationContent();
    }
}
