<template>
    <div class="form-group">
        <label>
            {{methodTypeName}}:
            <span v-if="selected && loadingCommunications" class="inline-loader"></span>
            <span v-else>{{statusMessage}}</span>
        </label>
        <label class="ps-toggle-switch">
            <input v-model="selected" class="toggle-checkbox" type="checkbox" v-bind:disabled="communicationSuccessful">
            <div class="toggle-switch"></div>
        </label>
        <label v-if="error" class="error">{{error}}</label>
    </div>
</template>

<script>
    import { CommunicationResendMixin } from "../../Shared/CommunicationResendMixin";

    export default {
        name: 'CardDetailsInviteCommunicationResend',
        mixins: [CommunicationResendMixin],
        props: {
            value: Object,
            customer: Object,
            inviteId: String,
            send: Number,
            methodType: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                selected: false
            }
        },
        computed: {
            methodTypeName() {
                return this.methodType === 1 ? 'Email' : this.methodType === 2 ? 'SMS' : 'Unknown';
            },
            clientId() {
                return !!this.customer ? this.customer.clientId : '';
            },
            communicationTypeId() {
                return 17;
            },
            subjectId() {
                return this.inviteId;
            },
            methodTypeId() {
                return this.methodType;
            },
            status() {
                return {
                    error: this.error,
                    completed: !this.selected || this.communicationSuccessful,
                    loading: this.loadingCommunications
                }
            },
            statusMessage() {
                return this.selected ? (this.attemptedCommunications.length > 0 ?
                    (!!this.communicationSuccessful ? 'Sent' : !!this.failedCommunication ? this.failedCommunication.failureReason : 'Unknown') :
                    'Not sent') : 'Inactive';
            }
        },
        watch: {
            status(value) {
                this.$emit('input', value);
            },
            send() {
                if (this.selected) {
                    this.tryUploadCommunication();
                }
            },
            selected() {
                this.emitSelected();
            }
        },
        methods: {
            emitSelected() {
                this.$emit('selected', this.selected);
            }
        },
        created() {
            this.emitSelected();
        }
    }
</script>
