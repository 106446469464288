<template>
    <tr>
        <td>{{cardTypeName}}</td>
        <td class="text-center">{{cardNumber}}</td>
        <td v-bind:class="statusClass" class="text-center">{{ status }}</td>
    </tr>
</template>

<script>
    export default {
        name: 'PaymentInstrumentsItem',
        props: {
            instrument: Object
        },
        computed: {
            cardType() {
                return !!this.instrument ? this.instrument.cardType : null;
            },
            cardTypeName() {
                return !!this.cardType ? this.cardType.description : ''
            },
            last4Digits() {
                return !!this.instrument ? this.instrument.last4Digits : '';
            },
            cardNumber() {
                return !!this.last4Digits ? "**** **** **** " + this.last4Digits : ''; 
            },
            statusType() {
                return !!this.instrument ? this.instrument.statusType : null;
            },
            statusTypeId() {
                return !!this.statusType ? this.statusType.id : 0;
            },
            statusClass() {
                return {
                    'text-success': this.statusTypeId === 1,
                    'text-danger': this.statusTypeId !== 1
                };
            },
            status() {
                return !!this.statusType ? this.statusType.description : "Unknown";
            }
        }
    }
</script>
