<template>
    <modal-dialog :title="$t('Add user')">
        <error-banner v-if="error" :message="error"></error-banner>
        <section class="rounded-card-white mb-3">
            <name-input v-model="name" :validator="userAddValidator"></name-input>
            <email-input v-model="empEmail" :validator="userAddValidator"></email-input>
            <sites-select v-model="empSiteIds" :validator="userAddValidator"></sites-select>
            <admin-toggle v-model="employeeSiteAdmin"></admin-toggle>
            <can-delete-toggle v-model="employeeCanDelete"></can-delete-toggle>
            <read-only v-model="employeeReadOnly"></read-only>
            <password-toggle v-model="empHasPassword"></password-toggle>
            <password-input v-if="empHasPassword" v-model="empPassword" :validator="userAddValidator"></password-input>
            <resource-add></resource-add>
            <payment-claims v-if="paymentApiUser" :employee-read-only="employeeReadOnly" :site-ids="paymentApiUser.owner"></payment-claims>
            <notification v-model="notificationStatus" :user="addedUser"></notification>
        </section>
        <div class="modal-footer">
            <button @click="save" v-bind:disabled="loading" class="btn btn-primary" type="button">
                {{$t('Add')}}
                <span v-if="loading" class="inline-loader"></span>
            </button>
        </div>
        <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
    </modal-dialog>
</template>

<script>
    import process from 'process';
    import ModalDialog from '../Layout/ModalDialog.vue';
    import { EmployeeAddMixin } from '../Shared/EmployeeAddMixin';
    import UserNameInput from './UserNameInput.vue';
    import UserAdminToggle from './UserAdminToggle.vue';
    import UserSitesSelect from './UserSitesSelect.vue';
    import UserPasswordToggle from './UserPasswordToggle.vue';
    import UserEmailInput from './UserEmailInput.vue';
    import UserPasswordInput from './UserPasswordInput.vue';
    import UserResourceAdd from './UserResourceAdd.vue';
    import UserCanDeleteToggle from './UserCanDeleteToggle.vue';
    import UserReadOnlyToggle from './UserReadOnlyToggle.vue'
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { PaymentApiUserCacheMixin } from '../../mixins/Payments/PaymentApiUserCacheMixin';
    import UserPaymentClaimsAdd from './UserPaymentClaimsAdd.vue';
    import UserNotificationAdd from './UserNotificationAdd.vue';

    export default {
        name: 'UserAdd',
        mixins: [EmployeeAddMixin, PaymentApiUserCacheMixin],
        data() {
            return {
                notificationStatus: {}
            }
        },
        computed: {
            employeeReadOnly: {
                get() {
                    return this.empIsReadOnly;
                },
                set(value) {
                    this.empIsReadOnly = !!value;
                    if (!!value) {
                        this.empIsSiteAdmin = !value;
                        this.empCanDelete = !value;
                    }
                }
            },
            employeeSiteAdmin: {
                get() {
                    return this.empIsSiteAdmin
                },
                set(value) {
                    this.empIsSiteAdmin = !!value;
                    if (!!value) {
                        this.empIsReadOnly = !value;
                    }
                }
            },
            employeeCanDelete: {
                get() {
                    return this.empCanDelete
                },
                set(value) {
                    this.empCanDelete = !!value;
                    if (!!value) {
                        this.empIsReadOnly = !value;
                    }
                }
            },
            name: {
                get() {
                    return {
                        firstName: this.empFirstName,
                        lastName: this.empLastName
                    }
                },
                set(value) {
                    this.empFirstName = value.firstName;
                    this.empLastName = value.lastName;
                }
            },
            statuses() {
                return [
                    this.notificationStatus
                ];
            },
            loading() {
                return this.addingEmployee || this.statuses.some(s => !!s.loading);
            },
            completed() {
                return !!this.addedUser && this.statuses.every(s => !!s.completed || !!s.error);
            }
        },
        watch: {
            completed(value) {
                if (!!value) {
                    process.nextTick(() => {
                        this.$refs.closeModal.click();
                    });
                }
            }
        },
        methods: {
            save() {
                this.tryAddEmployee();
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'name-input': UserNameInput,
            'sites-select': UserSitesSelect,
            'read-only': UserReadOnlyToggle,
            'admin-toggle': UserAdminToggle,
            'password-toggle': UserPasswordToggle,
            'email-input': UserEmailInput,
            'password-input': UserPasswordInput,
            'resource-add': UserResourceAdd,
            'can-delete-toggle': UserCanDeleteToggle,
            'error-banner': ErrorBanner,
            'payment-claims': UserPaymentClaimsAdd,
            'notification': UserNotificationAdd
        }
    }
</script>
