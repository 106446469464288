import { ErrorHelper } from "../../helpers/ErrorHelper";
import { PaymentApi } from "../../services/PaymentApi";

export const CustomerCheckoutAddMixin = {
    data() {
        return {
            error: '',
            addingCustomerCheckout: false,
            addedCheckout: null
        }
    },
    computed: {
        customerId() {
            console.log('OVERRIDE COMPUTED - customerId');
        },
        reference() {
            console.log('OVERRIDE COMPUTED - reference');
        },
        customerCheckoutAddModel() {
            return {
                id: this.customerId,
                reference: this.reference
            }
        },
        customerCheckoutAddModelValid() {
            const model = this.customerCheckoutAddModel
            return !!model.id && !!model.reference
        }
    },
    methods: {
        tryAddCustomerCheckout() {
            const self = this;
            if (!self.customerCheckoutAddModelValid) {
                return;
            }
            self.error = ''
            self.addingCustomerCheckout = true;
            PaymentApi.post('Customer/Checkout', self.customerCheckoutAddModel)
                .then(response => { self.afterAddCustomerCheckout(response.data) })
                .catch(error => ErrorHelper.getError(error))
                .finally(() => { self.addingCustomerCheckout = false; });
        },
        afterAddCustomerCheckout() {
            //override if necessary
        }
    }
}