<template>
    <div>
        <div v-if="subscriptionFeatureAllowed">
            <template v-if="pets.length < 1">
                <div class="alert-wrapper">
                    <div role="alert" class="alert alert-info alert-icon">
                        <div class="alert-content">
                            <p>{{$t('Registration invites are only permitted for clients with at least one pet. Please ensure that a pet is added for this client.')}}</p>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <error-banner v-if="error" :message="error"></error-banner>
                <div class="table-responsive">
                    <table class="table ps-table table-hover">
                        <thead>
                            <tr>
                                <th>{{$t('Method')}}</th>
                                <th>{{$t('Status')}}</th>
                                <th>{{$t('Sent')}}</th>
                                <th>{{$t('Action')}}</th>
                            </tr>
                        </thead>
                        <invite-email :id="clientId" @error="handleError" :toggle="toggle"></invite-email>
                        <invite-sms :id="clientId" @error="handleError" :toggle="toggle"></invite-sms>
                    </table>
                </div>
            </template>
        </div>
        <div v-else>
            <div class="alert-wrapper">
                <div role="alert" class="alert alert-info alert-icon">
                    <div class="alert-content">
                        <p>{{$t('Feature not available on your current subscription.')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SubscriptionFeatureMixin } from '../../mixins/Subscriptions/SubscriptionFeatureMixin.js';
    import { ClientOnboardingInviteCacheMixin } from '../../mixins/ClientOnboardingInvites/ClientOnboardingInviteCacheMixin.js';
    import { PetCacheMixinV2 } from '../Shared/PetCacheMixinV2.js';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import ClientOnboardingInviteEmail from './ClientOnboardingInviteEmail.vue';
    import ClientOnboardingInviteSms from './ClientOnboardingInviteSms.vue';

    export default {
        name: 'ClientOnboardingInviteSection',
        mixins: [ClientOnboardingInviteCacheMixin, SubscriptionFeatureMixin, PetCacheMixinV2],
        props: {
            id: String,
            toggle: Number
        },
        computed: {
            clientId() {
                return this.id;
            },
            computedFeatureKey() {
                return 'clientOnboarding';
            }
        },
        methods: {
            handleError(value) {
                this.error = value;
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'invite-email': ClientOnboardingInviteEmail,
            'invite-sms': ClientOnboardingInviteSms
        }
    }
</script>
