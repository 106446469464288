<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">
                {{$t('Resource Assignment Priority List')}}
                <tooltip>
                    <span>{{$t('Arrange resources in the list by priority. The first in the list will be assigned a pet first.')}}</span>
                </tooltip>
            </label>
        </div>
        <div class="col-md-8">
            <label for="title">
                {{$t('For Registered Clients:')}}
                <tooltip>
                    <span>{{$t("A resource set in the pet's profile takes precedence over the first resource in the list if available at the time of booking.")}}</span>
                </tooltip>
            </label>
            <div class="mw-400 rounded-card-white">
                <draggable v-model="forRegisteredClients" 
                           class="text-center"
                           @start="startDraggingForRegisteredClients"
                           @end="endDraggingForRegisteredClients">
                    <div v-for="(resourceId, index) in forRegisteredClients" :key="resourceId">
                        <draggable-item :resource-id="resourceId" :dragging="resourceId == draggingForRegisteredClients"></draggable-item>
                    </div>
                </draggable>
            </div>
            <template v-if="unregisteredClientBookingAllowed">
                <label for="title">{{$t('For Unregistered Clients:')}}</label>
                <div class="mw-400 rounded-card-white">
                    <draggable v-model="forNewClients"
                               class="text-center"
                               @start="startDraggingForNewClients"
                               @end="endDraggingForNewClients">
                        <div v-for="(resourceId, index) in forNewClients" :key="resourceId">
                            <draggable-item :resource-id="resourceId" :dragging="resourceId == draggingForNewClients"></draggable-item>
                        </div>
                    </draggable>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import { mapState } from 'vuex';
    import { SiteBookingResourceOrderSettingUploadMixin } from "../../../mixins/Settings/Site/SiteBookingResourceOrderSettingUploadMixin";
    import { StatusEmitterMixin } from '../../../mixins/Shared/StatusEmitterMixin';
    import { SubscriptionFeatureMixin } from "../../../mixins/Subscriptions/SubscriptionFeatureMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import ValidationMessage from '../../ErrorValidation/ValidationMessage.vue';
    import Tooltip from '../../Shared/Layout/Tooltip.vue';
    import SiteUpdateBookingResourceOrderItem from './SiteUpdateBookingResourceOrderItem.vue';

    export default {
        name: 'SiteUpdateBookingResourceOrder',
        mixins: [SiteBookingResourceOrderSettingUploadMixin, SubscriptionFeatureMixin, StatusEmitterMixin],
        data() {
            return {
                draggingForRegisteredClients: '',
                draggingForNewClients: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            status() {
                return {
                    loading: !!this.loadingResources || this.loadingSetting,
                    error: this.error
                }
            },
            settingEditModelModified() {
                return true;
            },
            computedFeatureKey() {
                return 'onlineBooking';
            },
            unregisteredClientBookingFeatureFound() {
                return !!this.subscriptionFeatures && !!this.subscriptionFeatures.hasOwnProperty('unregisteredClientBookingAllowed');
            },
            unregisteredClientBookingAllowed() {
                return this.unregisteredClientBookingFeatureFound ? this.subscriptionFeatures['unregisteredClientBookingAllowed'] : false;
            }
        },
        watch: {
            saveSiteEvent() {
                if (!!this.settingEditModelValid) {
                    this.tryUploadSetting();
                }
            }
        },
        methods: {
            startDraggingForRegisteredClients(value) {
                this.draggingForRegisteredClients = !!value.item && !!value.item._underlying_vm_ ? value.item._underlying_vm_ : '';
            },
            endDraggingForRegisteredClients(value) {
                this.draggingForRegisteredClients = '';
            },
            startDraggingForNewClients(value) {
                this.draggingForNewClients = !!value.item && !!value.item._underlying_vm_ ? value.item._underlying_vm_ : '';
            },
            endDraggingForNewClients(value) {
                this.draggingForNewClients = '';
            }
        },
        components: {
            draggable,
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage,
            'tooltip': Tooltip,
            'draggable-item': SiteUpdateBookingResourceOrderItem,
        }
    }
</script>
