<template>
    <nav class="primary-nav-expand">
        <img class="mobile-menu-logo" src="../../assets/images/logo_Black_Blue_Pink.svg" width="100" alt="Shake Your Tail">
        <ul>
            <nav-link menu-name="Bookings" icon-class="fas fa-globe" router-name="bookings" feature-key="onlineBooking"></nav-link>
            <nav-link menu-name="Breeds" icon-class="fas fa-paw" router-name="breeds"></nav-link>
            <nav-link menu-name="Services" icon-class="fas fa-cut" router-name="products"></nav-link>
            <nav-link menu-name="Sites" icon-class="fas fa-sitemap" router-name="sites"></nav-link>
            <nav-link menu-name="Users" icon-class="fas fa-user-group" router-name="users"></nav-link>
            <nav-link menu-name="Resources" icon-class="fas fa-box-open" router-name="resources"></nav-link>
            <nav-link menu-name="Documents" icon-class="fas fa-folder-open" router-name="contracts" feature-key="contracts"></nav-link>
            <main-expando :id="3" menu-name="Notifications" icon-class="fas fa-envelope">
                <sub-link menu-name="Email Templates" router-name="emailMarketingTemplates" feature-key="email"></sub-link>
                <sub-link menu-name="SMS Templates" router-name="smsMarketingTemplates" feature-key="sms"></sub-link>
                <sub-link menu-name="Sent" router-name="sentNotifications"></sub-link>
            </main-expando>
            <main-expando :id="4" menu-name="Campaigns" icon-class="fas fa-bullhorn">
                <sub-link menu-name="Campaigns" router-name="campaigns" feature-key="campaigns"></sub-link>
                <sub-link menu-name="Email Templates" router-name="campaignEmailTemplates" feature-key="campaigns"></sub-link>
                <sub-link menu-name="SMS Templates" router-name="campaignSmsTemplates" feature-key="campaigns"></sub-link>
            </main-expando>
            <nav-link menu-name="Reports" icon-class="fas fa-file-spreadsheet" router-name="reports" feature-key="reporting"></nav-link>
            <main-expando :id="5" menu-name="Payments" icon-class="fas fa-cart-shopping" :show-indicator="paymentFailuresCount > 0">
                <sub-link menu-name="Providers" router-name="paymentProviders" feature-key="payments"></sub-link>
                <sub-link menu-name="Reports" router-name="paymentReports" feature-key="payments"></sub-link>
                <sub-link-error menu-name="Oustanding" router-name="payment" feature-key="payments" :error-count="paymentFailuresCount"></sub-link-error>
            </main-expando>
            <nav-link menu-name="Store" icon-class="fas fa-store" router-name="store"></nav-link>
            <main-expando :id="6" menu-name="Account" icon-class="fas fa-user-circle">
                <sub-link menu-name="Profile & Security" router-name="myAccount"></sub-link>
                <sub-link menu-name="Purchase History" router-name="purchaseHistory"></sub-link>
                <sub-link menu-name="Billing" router-name="paymentUpdate"></sub-link>
                <sub-link menu-name="Referrals" router-name="referrals"></sub-link>
                <sub-link menu-name="Migration" router-name="migration" v-if="migrationAllowed"></sub-link>
            </main-expando>
        </ul>
    </nav>
</template>

<script>
    import { SubscriptionPaymentFailuresCacheMixin } from "../../mixins/SubscriptionPaymentFailures/SubscriptionPaymentFailuresCacheMixin";
    import { MigrationAllowedMixin } from "../../mixins/Migrations/MigrationAllowedMixin";
    import NavPanelExpandLink from "./NavPanelExpandLink.vue";
    import NavPanelExpando from "./NavPanelExpando.vue";
    import NavPanelSubLink from "./NavPanelSubLink.vue";
    import NavPanelSubLinkWithError from "./NavPanelSubLinkWithError.vue";

    export default {
        name: 'NavPanelExpand',
        mixins: [SubscriptionPaymentFailuresCacheMixin, MigrationAllowedMixin],
        computed: {
            paymentFailuresCount() {
                return this.paymentFailures ? this.paymentFailures.length : 0;
            }
        },
        methods: {
            handleResize() {
                const viewportHeight = window.innerHeight;
                document.querySelector('.primary-nav-expand').style.setProperty(
                    'height',
                    `calc(${viewportHeight}px - 48px)`,
                    'important'
                );
            },
            isMobileDevice() {
                return window.innerWidth < 768;
            }
        },
        mounted() {
            if (this.isMobileDevice()) {
                this.handleResize(); // Initial adjustment
                window.addEventListener('resize', this.handleResize);
            }
        },
        beforeDestroy() {
            if (this.isMobileDevice()) {
                window.removeEventListener('resize', this.handleResize);
            }
        },
        components: {
            'nav-link': NavPanelExpandLink,
            'main-expando': NavPanelExpando,
            'sub-link': NavPanelSubLink,
            'sub-link-error': NavPanelSubLinkWithError,
        }
    }
</script>
