<template>
    <div v-if="isSiteAdmin">
        <start-day v-model="startDay"></start-day>
        <hidden-days v-model="hiddenDays"></hidden-days>
        <appointment-hours v-model="appointmentHours" :message="appointmentHoursMessage"></appointment-hours>
        <hidden-hours v-model="nonBusinessHoursAmount"></hidden-hours>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { SiteScheduleSettingUploadMixin } from "../../../mixins/Settings/Site/SiteScheduleSettingUploadMixin";
    import { UserCacheMixin } from "../../Shared/UserCacheMixin";
    import ScheduleAppointmentHours from "./ScheduleAppointmentHours.vue";
    import ScheduleHiddenDays from './ScheduleHiddenDays.vue';
    import ScheduleStartDay from './ScheduleStartDay.vue';
    import ScheduleHiddenNonBusinessHours from "./ScheduleHiddenNonBusinessHours.vue";

    export default {
        name: 'ScheduleSiteSettings',
        mixins: [UserCacheMixin, SiteScheduleSettingUploadMixin],
        data() {
            return {
                appointmentHoursMessage: ''
            }  
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveScheduleSettingsEvent'
            ]),
            appointmentHours: {
                get() {
                    return {
                        startHour: this.startHour,
                        endHour: this.endHour
                    }
                },
                set(value) {
                    this.startHour = value.startHour;
                    this.endHour = value.endHour;
                }
            },
            settingEditModelValid() {
                const model = this.settingEditModel;
                return model.startHour < model.endHour;
            }
        },
        watch: {
            loadingSetting(value) {
                this.$emit('input', value);
            },
            saveScheduleSettingsEvent() {
                if (!!this.settingEditModelModified) {
                    this.validate();
                }
                this.tryUploadSetting();
            }
        },
        methods: {
            validate() {
                const model = this.settingEditModel
                this.appointmentHoursMessage = model.startHour < model.endHour ? '' : "Schedule start hour must be less than end hour."
            }
        },
        components: {
            'start-day': ScheduleStartDay,
            'hidden-days': ScheduleHiddenDays,
            'appointment-hours': ScheduleAppointmentHours,
            'hidden-hours': ScheduleHiddenNonBusinessHours
        }
    }
</script>
