import { Api } from '../../services/Api';

const state = () => ({
    communicationContentCaches: []
})

const getters = {
    findCommunicationContentCache: (state) => key => state.communicationContentCaches.find(ccc => ccc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.communicationContentCaches.find(ccc => ccc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.communicationContentCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setCommunicationContent(state, communicationContent) {
        const existing = state.communicationContentCaches.find(ccc => ccc.key === communicationContent.id);
        if (!!existing) {
            existing.value = communicationContent;
            existing.loading = false;
        } else {
            state.communicationContentCaches.push({ key: communicationContent.id, value: communicationContent, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.communicationContentCaches.find(ccc => ccc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    getCommunicationContent({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: id, loading: true });
            Api.get('Communication/' + id + '/Content')
                .then(response => {
                    commit('setCommunicationContent', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', id);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
