import axios from 'axios';
import store from '../store';
import router from '../routing';

export const Api = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_AXIOS_BASE_URL || '#{Project.Api.Endpoint}'
})

Api.interceptors.request.use(config => {
    const accountSiteId = store.state.Sites.selected.id;
    if (!!accountSiteId) {
        config.headers.AccountSiteId = accountSiteId;
    }
    return config;
});

Api.interceptors.response.use(
    r => r,
    error => {
        if (error.response.status === 401 || error.response.status === 403) {
            const path = router.currentRoute.path;
            const query = router.currentRoute.query;

            // Check if the url contains "Notification"
            const url = error.response.config?.url;
            const isNotification = url?.includes('Notification');

            store.dispatch('Authentication/logout', {
                queryObj: {
                    rPath: path,
                    rQuery: new URLSearchParams(query)
                },
                unauthorized: isNotification ? false : path !== '/interstitial'
            });
        }
        return Promise.reject(error);
    });
