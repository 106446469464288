<template>
    <div v-if="displaySelect" class="form-group row">
        <div class="col-md-4">
            <label>{{title}}</label>
        </div>
        <div class="col-md-8">
            <div class="dropdown w-100">
                <button aria-expanded="false"
                        aria-haspopup="true"
                        data-toggle="dropdown"
                        id="dropdownMenuButton2"
                        type="button"
                        class="filter-dd text-left w-100 mw-400"
                        v-bind:disabled="disable">
                    <multi-select-display :values="summary"></multi-select-display>
                </button>
                <div class="dropdown-menu py-1 w-100 mw-400">
                    <div v-for="site in paymentSiteOptions" v-if="!disable" :key="site.id" class="dropdown-item px-2">
                        <label class="custom-control custom-checkbox">
                            <input v-model="site.selected" @change="onOptionsChanged" class="custom-control-input" type="checkbox">
                            <span class="custom-control-indicator"></span>
                            <span class="custom-control-description">{{site.name}}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SiteCacheMixin } from "../Shared/SiteCacheMixin";
    import MultiSelectDropdownDisplay from "../Shared/Layout/MultiSelectDropdownDisplay.vue"

    export default {
        name: 'PaymentSiteMultiSelect',
        mixins: [SiteCacheMixin],
        props: {
            value: Array,
            title: String,
            siteIds: Array,
            disable: false
        },
        computed: {
            paymentSites() {
                return this.siteIds.map(id => this.sites.find(s => s.id === id)).filter(s => !!s);
            },
            paymentSiteOptions() {
                return this.paymentSites.map(s => Object.assign({}, s, { selected: this.value.indexOf(s.id) > -1 }))
            },
            displaySelect() {
                return this.paymentSites.length > 0;
            },
            summary() {
                const selected = this.paymentSiteOptions.filter(o => o.selected).map(o => o.name);
                return selected.length > 0 ? selected : ['Locations'];
            }
        },
        methods: {
            onOptionsChanged() {
                this.$emit('input', this.paymentSiteOptions.filter(o => o.selected).map(o => o.id));
            }
        },
        components: {
            'multi-select-display': MultiSelectDropdownDisplay
        }
    }
</script>
