import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const PaymentInstrumentSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('PaymentInstrumentSettings', [
            'fetchedPaymentInstrumentSettings',
            'fetchingPaymentInstrumentSettings',
            'paymentInstrumentSettings'
        ]),
        paymentInstrumentsEnabled() {
            return !!this.paymentInstrumentSettings && this.paymentInstrumentSettings.enabled;
        }
    },
    methods: {
        ...mapActions('PaymentInstrumentSettings', [
            'fetchPaymentInstrumentSettings'
        ]),
        refreshPaymentInstrumentSettings() {
            if (!this.fetchedPaymentInstrumentSettings && !this.fetchingPaymentInstrumentSettings) {
                this.tryFetchPaymentInstrumentSettings();
            }
        },
        tryFetchPaymentInstrumentSettings() {
            const self = this;

            self.error = '';
            self.fetchPaymentInstrumentSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPaymentInstrumentSettings();
    }
}
