<template>
    <div>
        <div v-if="subscriptionFeatureAllowed">
            <error-banner v-if="!!error" :message="error"></error-banner>
            <span v-if="loading" class="inline-loader"></span>
            <template v-else-if="!!noPaymentOAuths">
                <div role="alert" class="alert alert-info alert-icon">
                    <div class="alert-content">
                        <p>
                            This site isn't connected to a payment provider.
                            <template v-if="isOwner">&nbsp;Click&nbsp;<a @click="gotoPaymentProviders">HERE</a> to connect.</template>
                        </p>
                    </div>
                </div>
            </template>
            <div v-else-if="displayTable" class="table-responsive">
                <table class="table ps-table table-hover">
                    <thead>
                        <tr>
                            <th class="col-3">{{$t('Provider')}}</th>
                            <th class="col-2">{{$t('Date')}}</th>
                            <th class="col-2">{{$t('Status')}}</th>
                            <th class="col-5">{{$t('Action')}}</th>
                        </tr>
                    </thead>
                    <payment-customer-item v-for="(provider, index) in sitePaymentProviders"
                                           :number="index"
                                           :key="provider.id"
                                           :provider="provider"
                                           :client-id="clientId">
                    </payment-customer-item>
                </table>
            </div>
        </div>
        <template v-else>
            <div role="alert" class="alert alert-info alert-icon">
                <div class="alert-content">
                    <p>{{$t('Feature not available on your current subscription.')}}</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { PaymentProvidersCacheMixin } from "../../Payments/PaymentProvidersCacheMixin";
    import { PaymentCustomersCacheMixin } from "../../Payments/PaymentCustomersCacheMixin";
    import { PaymentOAuthsCacheMixin } from "../../Payments/PaymentOAuthsCacheMixin";
    import { SubscriptionFeatureMixin } from '../../../mixins/Subscriptions/SubscriptionFeatureMixin';
    import { UserCacheMixin } from '../../Shared/UserCacheMixin';
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import PaymentCustomerItem from "./PaymentCustomerItem.vue"

    export default {
        name: 'PaymentCustomers',
        mixins: [PaymentProvidersCacheMixin, PaymentOAuthsCacheMixin, PaymentCustomersCacheMixin, SubscriptionFeatureMixin, UserCacheMixin],
        data() {
            return {
                providerError: String
            }
        },
        computed: {
            computedFeatureKey() {
                return 'payments'
            },
            providersInOAuths() {
                return this.paymentOAuths.length > 0 ? this.paymentOAuths.map(oauth => oauth.providerType.id) : [];
            },
            sitePaymentProviders() {
                return this.paymentProviders.length > 0 ?
                    this.paymentProviders.filter(provider => this.providersInOAuths.includes(provider.id)) : [];
            },
            noPaymentOAuths() {
                return this.paymentOAuths.length < 1 && !this.loadingPaymentOAuths
            },
            displayTable() {
                return this.sitePaymentProviders.length > 0;
            },
            loading() {
                return !!this.fetchingPaymentProviders || !!this.loadingPaymentOAuths
            }
        },
        methods: {
            gotoPaymentProviders() {
                this.$router.push({ name: 'paymentProviders' });
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'payment-customer-item': PaymentCustomerItem
        }
    }
</script>
