<template>
    <div>
        <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-end align-items-center mb-2 w-100">
            <site-select class="mb-3 mb-md-0 mr-0 mr-md-auto"></site-select>
            <week-select v-if="!!resource" v-model="date"></week-select>
            <button v-if="!!resource"
                    v-bind:disabled="!!loadingWorkingHour || !!loadingOneOffHours"
                    @click="displayCalendar" type="button"
                    class="btn btn-sm btn-outline-secondary mt-3 mt-md-0 ml-md-4 ml-0">
                <i class="fal fa-table"></i>
                {{$t('Monthly')}}
            </button>
        </div>
        <div v-if="!resource" class="alert-wrapper px-0">
            <div role="alert" class="alert alert-info alert-icon">
                <div class="alert-content">
                    <p>{{$t('User is not a resource of the selected site.')}}</p>
                </div>
            </div>
        </div>
        <template v-else>
            <table-mobile>
                <ul class="nav nav-tabs-ps d-flex" role="tablist">
                    <li class="nav-item col-6">
                        <a class="nav-link active" data-toggle="tab" href="#opening-hours-pane" role="tab">{{$t('Opening Hours')}}</a>
                    </li>
                    <li class="nav-item col-6">
                        <a class="nav-link" data-toggle="tab" href="#working-hours-pane" role="tab">{{$t('Working Hours')}}</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <br />
                    <div class="tab-pane fade show active" id="opening-hours-pane">
                        <header-mobile v-for="dayId in dayIds" :day-id="dayId" :opening-hours="openingHours" :key="dayId"></header-mobile>
                    </div>
                    <div class="tab-pane fade" id="working-hours-pane">
                        <resource-mobile :resource="resource" :working-hours="workingHours" :one-off-hours="oneOffHours"></resource-mobile>
                    </div>
                </div>
            </table-mobile>
            <table-desktop>
                <template #header>
                    <tr role="row">
                        <opening-hours v-for="dayId in dayIds" :day-id="dayId" :opening-hours="openingHours" :key="dayId"></opening-hours>
                    </tr>
                </template>
                <template #rows>
                    <resource-hours :resource="resource" :working-hours="workingHours" :one-off-hours="oneOffHours"></resource-hours>
                </template>
            </table-desktop>
            <button ref="calendarButton" type="button" data-target="#calendar-modal" data-toggle="modal" style="display: none"></button>
            <modal-dialog id="calendar-modal" :title="$t('Monthly Schedule')">
                <section ref="calendarContent" class="rounded-card-white mb-3 text-center">
                    <div class="d-flex flex-column flex-md-row">
                        <div class="col-md-6 col-12">
                            <div class="form-group row">
                                <div class="col-md-4 col-2 px-0">
                                    <label class="text-left">Name:</label>
                                </div>
                                <div class="col-md-8 col-10 px-0">
                                    <p class="text-left">{{resourceName}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="form-group row">
                                <div class="col-md-4 col-2 px-0">
                                    <label class="text-left">Site:</label>
                                </div>
                                <div class="col-md-8 col-10 px-0">
                                    <p class="text-left">{{siteName}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h6 class="d-md-none d-block">{{monthYear}}</h6>
                    <h4 class="d-md-block d-none">{{monthYear}}</h4>
                    <resource-events v-model="date" :resource="resource"></resource-events>
                </section>
                <div class="d-flex justify-content-center">
                    <button ref="cancelButton" type="button" class="btn btn-outline-secondary mr-2" data-dismiss="modal">{{$t('Close')}}</button>
                    <button @click="downloadCalendar" type="button" class="btn btn-primary">
                        {{$t('Download')}}
                        <span v-if="!!downloading" class="inline-loader"></span>
                    </button>
                </div>
            </modal-dialog>
        </template>
    </div>
</template>

<script>
    import { WorkingHoursCacheMixin } from "../Shared/WorkingHours/WorkingHoursCacheMixin";
    import { OpeningHoursCacheMixin } from "../Shared/OpeningHours/OpeningHoursCacheMixin";
    import { OneOffHoursSearchMixin } from "../Shared/OneOffHours/OneOffHoursSearchMixin";
    import { SiteCacheMixin } from "../Shared/SiteCacheMixin";
    import { SelfUserResourcesCacheMixin } from "../Shared/SelfUserResourcesCacheMixin";
    import UserResourceWeekSelector from "./UserResourceWeekSelector.vue";
    import UserResourceOpeningHours from "./UserResourceOpeningHours.vue";
    import UserResourceHours from "./UserResourceHours.vue";
    import TableDesktop from "../Shared/Layout/TableDesktop.vue";
    import TableMobile from "../Shared/Layout/TableMobile.vue";
    import jsPDF from 'jspdf';
    import UserResourceEvents from "./UserResourceEvents.vue";
    import ModalDialog from "../Layout/ModalDialog.vue";
    import SiteSelect from "./SiteSelect.vue";
    import OpeningHoursMobile from "../Resources/ResourceHours/OpeningHoursMobile.vue";
    import UserResourceHoursMobile from "./UserResourceHoursMobile.vue";

    export default {
        name: 'UserResource',
        mixins: [SiteCacheMixin, SelfUserResourcesCacheMixin, OpeningHoursCacheMixin, WorkingHoursCacheMixin, OneOffHoursSearchMixin],
        data() {
            return {
                date: new Date(),
                downloading: false
            }
        },
        computed: {
            now() {
                return this.date;
            },
            contentWidth() {
                return this.$refs.calendarContent.offsetWidth;
            },
            siteUserResources() {
                return this.selfUserResources.filter(ur => ur.siteId === this.siteId && !ur.hidden);
            },
            resource() {
                return this.siteUserResources.length > 0 ? this.siteUserResources[0] : null;
            },
            resourceName() {
                return !!this.resource ? this.resource.name : '-';
            },
            monthYear() {
                const month = this.date.toLocaleString('default', { month: 'long' });
                const year = this.date.getFullYear();
                return `${month} ${year}`;
            }
        },
        methods: {
            displayCalendar() {
                if (!!this.resource) {
                    this.$refs.calendarButton.click();
                }
            },
            downloadCalendar() {
                this.downloading = true;
                const content = this.$refs.calendarContent;

                const doc = new jsPDF({
                    orientation: 'portrait',
                    unit: 'mm',
                    format: 'a4',
                });

                const contentWidth = content.offsetWidth;
                const borderInline = 10;
                const borderBlock = 10;
                const a4Width = 210 - (borderInline * 2);

                const scaleWidth = a4Width / contentWidth;

                doc.html(content, {
                    callback: (doc) => {
                        doc.save(`${this.monthYear} Schedule - ${this.resourceName}.pdf`);
                        this.downloading = false;
                    },
                    x: borderInline,
                    y: borderBlock,
                    html2canvas: {
                        scale: scaleWidth,
                        letterRendering: true,
                    },
                    useCORS: true
                });
            }
        },
        components: {
            'week-select': UserResourceWeekSelector,
            'table-desktop': TableDesktop,
            'table-mobile': TableMobile,
            'opening-hours': UserResourceOpeningHours,
            'resource-hours': UserResourceHours,
            'resource-events': UserResourceEvents,
            'modal-dialog': ModalDialog,
            'site-select': SiteSelect,
            'header-mobile': OpeningHoursMobile,
            'resource-mobile': UserResourceHoursMobile
        }
    }
</script>
