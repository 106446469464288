<template>
    <div class="user-resource-week-selector text-md-center text-right ml-md-auto ml-0">
        <div class="calendar-control day-selector btn-group">
            <button class="btn" title="Prev" type="button" @click="movePrev">
                <i class="fas fa-chevron-left"></i>
                <span class="sr-only">Prev</span>
            </button>
            <div class="date-selector">
                <date-picker v-model="date" input-class="schedule-date" :show-calendar-button="true"></date-picker>
            </div>
            <button class="btn" title="Next" type="button" @click="moveNext">
                <i class="fas fa-chevron-right"></i>
                <span class="sr-only">Next</span>
            </button>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import MyDatePicker from '../Shared/Time/MyDatePicker.vue';

    export default {
        name: 'UserResourceWeekSelector',
        props: {
            value: Date
        },
        computed: {
            date: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        methods: {
            movePrev() {
                this.$emit('input', moment(this.value).subtract(1, 'weeks').toDate());
            },
            moveNext() {
                this.$emit('input', moment(this.value).add(1, 'weeks').toDate());
            }
        },
        components: {
            'date-picker': MyDatePicker
        }
    }
</script>
