<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" class="accordion-header" data-target="#collapse-mpo-1" data-toggle="collapse">
            <i class="fas fa-calculator mr-2"></i>
            {{$t('Calculator (Estimated)')}}
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse show" id="collapse-mpo-1">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <span v-if="fetchingSmsCalculatorSettings || loadingSmsUnitCost" class="inline-loader"></span>
                <template v-if="enabled && unitCost > 0">
                    <template v-if="!siteCountryValid">
                        <div class="alert alert-info alert-icon" role="alert">
                            <div class="alert-content">
                                <p>{{$t('Cost calculator is not available for site country.')}}</p>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="alert alert-info alert-icon" role="alert">
                            <div class="alert-content">
                                <p>{{$t('Emojis add personality but may increase costs. Try the calculator without them.')}}</p>
                            </div>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <i class="fa fa-close" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="form-group row">
                            <label class="col-6">{{$t('No. of messages')}}:</label>
                            <label class="col-6">&nbsp;&nbsp;&nbsp;{{ chunks }}</label>
                        </div>
                        <div class="form-group row">
                            <label class="col-6">{{$t('Credit cost per message')}}:</label>
                            <label class="col-6">x {{ unitCost }}</label>
                        </div>
                        <div class="title-divider m-0"></div>
                        <div class="form-group row">
                            <label class="col-6">{{$t('Total credit cost')}}:</label>
                            <label class="col-6">&nbsp;&nbsp;&nbsp;{{ totalCost }}</label>
                        </div>
                    </template>
                </template>
                <template v-if="(fetchedSmsCalculatorSettings && !enabled) || (enabled && unitCost == 0 && !loadingSmsUnitCost)">
                    <div class="alert alert-info alert-icon" role="alert">
                        <div class="alert-content">
                            <p>{{$t('Cost calculator is not available at the moment.')}}</p>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
    import { SmsUnitCostCacheMixin } from '../Shared/SmsUnitCostCacheMixin';
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'SmsCreditCostCalculator',
        mixins: [SmsUnitCostCacheMixin],
        props: {
            message: ''
        },
        computed: {
            hasEmoji() {
                return new RegExp(/[\uD800-\uDBFF][\uDC00-\uDFFF]/).test(this.message);
            },
            messageLength() {
                if (!this.message) return 0;

                // GSM 7-bit character set
                const gsm7bitCharset =
                    `@Δ £_$¥èéùìòÇ\nØø\rÅå
                    ΔΦΓΛΩΠΨΣΘΞ
                    SP!"#¤%&'()*+,-./
                    0123456789:;<=>?
                    ¡¿A BCDEFGHIJKLMNOPQRSTUVWXYZa bcdefghijklmnopqrstuvwxyzäöñüàÄÖÑÜ§
                    \x1b`;

                let length = 0;

                for (let char of this.message) {
                    if (gsm7bitCharset.includes(char)) {
                        length += 1;
                    } else {
                        length += (char.length * 2);
                    }
                }

                return length;
            },
            gsmChunks() {
                return Math.ceil(this.messageLength / 153);
            },
            utfChunks() {
                return Math.ceil(this.messageLength / 67);
            },
            chunks() {
                return !!this.hasEmoji ? this.utfChunks : this.gsmChunks;
            },
            totalCost() {
                return parseInt(Math.round(this.chunks * this.unitCost));
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>