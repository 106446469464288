<template>
    <div>
        <site-select v-model="adminAccounts" :disable="employeeReadOnly" :siteIds="siteIds" title="Payment Admin"></site-select>
        <site-select v-model="refunderAccounts" :disable="employeeReadOnly" :siteIds="siteIds" title="Payment Refunds"></site-select>
    </div>
</template>

<script>
    import { PaymentApiUserClaimUpdateMixin } from "../../mixins/Payments/PaymentApiUserClaimUpdateMixin";
    import { PaymentUserAddMixin } from "../../mixins/Payments/PaymentUserAddMixin";
    import { mapState } from 'vuex';
    import PaymentSiteMultiSelect from "../Payments/PaymentSiteMultiSelect.vue";

    export default {
        name: 'UserPaymentClaimsUpdate',
        mixins: [PaymentApiUserClaimUpdateMixin, PaymentUserAddMixin],
        props: {
            employee: Object,
            siteIds: Array
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveUserEvent'
            ]),
            userId() {
                return !!this.employee ? this.employee.id : '';
            }
        },
        watch: {
            saveUserEvent() {
                if (!!this.paymentApiUser) {
                    this.tryUpdatePaymentApiUserClaims();
                } else {
                    this.tryAddPaymentUserWithCompletion(this.tryUpdatePaymentApiUserClaims);
                }
            }
        },
        components: {
            'site-select': PaymentSiteMultiSelect
        }
    }
</script>
