<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-envelope fa-1x"></i>
                    {{$t('Sent Notifications')}}
                </h1>
            </div>
            <div class="secondary-nav">
                <div class="search-field-static">
                    <div class="search-field">
                        <input v-model="searchTerm" aria-label="Search" autocomplete="off" class="search-field-input" id="client-search" :placeholder="$t('Search client name')" spellcheck="false" type="search">
                        <button class="search-field-btn" type="submit">
                            <span v-if="searchingCommunications || searchingClients" class="inline-loader"></span>
                            <template v-else>
                                <i class="fas fa-search"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
            <div class="header-tools">
                <site-select class="ml-auto mr-2 d-md-block d-none dropdown-site-selector"></site-select>
            </div>
        </header>
        <div class="content">
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <div class="alert alert-info alert-icon" role="alert">
                <div class="alert-content">
                    <p>{{$t('Only successfully sent communications from the past month are currently being displayed.')}}</p>
                </div>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <i class="fa fa-close" aria-hidden="true"></i>
                </button>
            </div>
            <!-- Table shows for mobile, desktop has own view -->
            <communications-mobile :search-key="searchKey" @skipTo="skipTo" @communicationClicked="onCommunicationClicked"></communications-mobile>
            <!-- Table shows for desktop, mobile has own view -->
            <table-desktop :page-number="communicationPageNumber" @next="next" @previous="previous">
                <template #header>
                    <tr role="row">
                        <sort-header class="col-2" v-model="sortModel" key-name="editDate">{{$t('Date Sent')}}</sort-header>
                        <th class="col-3">{{$t('Client Name')}}</th>
                        <th class="col-2" style="text-align: left">
                            <div class="dropdown">
                                <a role="button" data-toggle="dropdown">
                                    {{$t('Method')}}
                                    <i class="far fa-filter"></i>
                                </a>
                                <div class="dropdown-menu">
                                    <div v-for="methodType in methodTypes" class="dropdown-item" v-bind:class="{ 'active': methodTypeId===methodType.id }">
                                        <a class="pointer" @click="methodTypeId=methodType.id">{{$t(methodType.description)}}</a>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th class="col-3" style="text-align: left">
                            <div class="dropdown">
                                <a role="button" data-toggle="dropdown">
                                    {{$t('Subject')}}
                                    <i class="far fa-filter"></i>
                                </a>
                                <div class="dropdown-menu">
                                    <div class="dropdown-item" v-bind:class="{ 'active': communicationTypeId===0 }">
                                        <a class="pointer" @click="communicationTypeId=0">{{$t('All')}}</a>
                                    </div>
                                    <div v-for="communicationType in communicationTypes" class="dropdown-item" v-bind:class="{ 'active': communicationTypeId===communicationType.id }">
                                        <a class="pointer" @click="communicationTypeId=communicationType.id">{{$t(communicationType.description)}}</a>
                                    </div>
                                </div>
                            </div>
                        </th>
                    </tr>
                </template>
                <template #rows>
                    <communication-desk v-for="(communication, index) in communications" 
                                        @communicationClicked="onCommunicationClicked"
                                        :key="communication.id" 
                                        :communication="communication" 
                                        :number="index"></communication-desk>
                </template>
            </table-desktop>
        </div>
        <communication-view ref="viewCommunication" :communication="communication" :client="client" @closed="onClosed"></communication-view>
    </div>
</template>

<script>
    import { HashHelper } from '../../helpers/HashHelper';
    import { SiteIdMixin } from '../Shared/Site/SiteIdMixin';
    import { CommunicationSearchMixin } from '../Shared/CommunicationSearchMixin';
    import { mapActions, mapGetters } from "vuex"
    import { CacheHelper } from '../../helpers/CacheHelper';
    import { ErrorHelper } from '../../helpers/ErrorHelper';
    import { CommunicationTypesCacheMixin } from '../Shared/CommunicationTypesCacheMixin';
    import CommunicationItemDesktop from './CommunicationItemDesktop.vue';
    import TableDesktop from '../Shared/Layout/TableDesktop.vue';
    import TableSortableHeader from '../Shared/Layout/TableSortableHeader.vue';
    import SiteSelect from '../Account/SiteSelect.vue';
    import CommunicationsMobile from './CommunicationsMobile.vue';
    import CommunicationView from './CommunicationView.vue';

    export default {
        name: 'Communications',
        mixins: [SiteIdMixin, CommunicationSearchMixin, CommunicationTypesCacheMixin],
        data() {
            return {
                methodTypes: [
                    { id: 0, description: 'All' },
                    { id: 1, description: 'Email' },
                    { id: 2, description: 'SMS' }
                ],
                communication: null,
                client: null
            }
        },
        computed: {
            ...mapGetters('ClientSearchesV2', [
                'findClientSearch'
            ]),
            searchKey() {
                return HashHelper.createStringHash(this.siteId + this.searchTerm + this.sortKey + this.communicationTypeId + this.methodTypeId + this.ascending) + '-';
            },
            communicationSearchKey() {
                return this.searchKey + this.skip + this.take;
            },
            clientIds() {
                const uniqueClientIds = new Set(this.communications.map(c => c.clientId));
                return Array.from(uniqueClientIds);
            },
            clientSearchModel() {
                return {
                    clientIds: this.clientIds
                }
            },
            clientSearchModelValid() {
                return this.clientSearchModel.clientIds.length > 0;
            },
            clientSearchKey() {
                return CacheHelper.objectCacheKey(this.clientSearchModel);
            },
            clientSearch() {
                return this.findClientSearch(this.clientSearchKey);
            },
            clients() {
                return !!this.clientSearch ? this.clientSearch.values : [];
            },
            searchingClients() {
                return !!this.clientSearch && this.clientSearch.loading;
            }
        },
        watch: {
            clientSearchKey() {
                this.refreshClientSearch();
            }
        },
        methods: {
            ...mapActions('ClientSearchesV2', [
                'searchClients'
            ]),
            refreshClientSearch() {
                if (!this.clientSearch || !!this.clientSearch.retry) {
                    this.searchAndCacheClients();
                }
            },
            searchAndCacheClients() {
                const self = this;
                self.error = '';
                if (!self.clientSearchModelValid) {
                    return;
                }
                self.searchClients({ key: self.clientSearchKey, payload: self.clientSearchModel })
                    .catch(error => self.error = ErrorHelper.getError(error));
            },
            onCommunicationClicked(value) {
                this.communication = value;
                this.client = this.clients.find(c => c.id === this.communication.clientId);
                const element = this.$refs.viewCommunication.$el;
                $(element).modal('show');
            },
            onClosed() {
                this.communication = null;
                this.client = null;
            }
        },
        created() {
            this.refreshCommunicationSearch();
        },
        components: {
            'site-select': SiteSelect,
            'communication-desk': CommunicationItemDesktop,
            'table-desktop': TableDesktop,
            'sort-header': TableSortableHeader,
            'communications-mobile': CommunicationsMobile,
            'communication-view': CommunicationView
        }
    }
</script>
