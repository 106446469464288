import { ErrorHelper } from "../../helpers/ErrorHelper";
import { Api } from "../../services/Api";

export const CardDetailsInviteAddMixin = {
    data() {
        return {
            error: '',
            loadingInvite: false,
            addedCardDetailsInvite: null
        }
    },
    computed: {
        clientId() {
            console.log('OVERRIDE COMPUTED - clientId');
        },
        providerTypeId() {
            console.log('OVERRIDE COMPUTED - providerTypeId');
        },
        cardDetailsInviteAddModel() {
            return {
                clientId: this.clientId,
                providerTypeId: this.providerTypeId
            }
        },
        cardDetailsInviteAddModelValid() {
            const model = this.cardDetailsInviteAddModel
            return !!model.clientId && !!model.providerTypeId
        }
    },
    methods: {
        tryAddCardDetailsInvite() {
            const self = this;
            if (!self.cardDetailsInviteAddModelValid) {
                return;
            }
            self.error = ''
            self.loadingInvite = true;
            Api.post('CardDetailsInvite', self.cardDetailsInviteAddModel)
                .then(response => { self.afterAddCardDetailsInvite(response.data) })
                .catch(error => ErrorHelper.getError(error))
                .finally(() => { self.loadingInvite = false; });
        },
        afterAddCardDetailsInvite() {
            //override if necessary
        }
    }
}