import moment from 'moment';
import { ClientCacheMixin } from '../Shared/ClientCacheMixin';
import { DateDisplayMixin } from '../Shared/Time/DateDisplayMixin';
import { TimeDisplayMixin } from '../Shared/Time/TimeDisplayMixin';

export const CommunicationItemMixin = {
    mixins: [DateDisplayMixin, TimeDisplayMixin, ClientCacheMixin],
    props: {
        communication: Object
    },
    computed: {
        lastUpdatedDate() {
            return !!this.communication ? moment(this.communication.lastUpdatedUtc).format(this.momentJSFormat) : "-";
        },
        lastUpdatedTime() {
            return !!this.communication ? moment(this.communication.lastUpdatedUtc).format(this.timeMomentJSFormat) : "-";
        },
        lastUpdatedDateTime() {
            return this.lastUpdatedDate + ' ' + this.lastUpdatedTime;
        },
        clientId() {
            return !!this.communication ? this.communication.clientId : ''
        },
        fullName() {
            return !!this.client ? this.client.fullName : 'Unknown';
        },
        methodType() {
            return !!this.communication ? this.communication.methodType : null;
        },
        methodTypeDescription() {
            return !!this.communication ? this.$t(this.methodType.description) : this.$t('Unknown');
        },
        communicationType() {
            return !!this.communication ? this.communication.communicationType : null;
        },
        communicationTypeDescription() {
            return !!this.communicationType ? this.$t(this.communicationType.description) : this.$t('Unknown');
        }
    },
    methods: {
        onClick() {
            this.$emit('communicationClicked', this.communication);
        },
        refreshClient() {
            //do nothing
        }
    }
}
