<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Frequency')}}</label>
        </div>
        <div class="col-md-8">
            <div class="d-flex mw-400">
                <div class="col-6 pl-0">
                    <label for="title">{{$t('Period')}}</label>
                    <div class="bootstrap-select form-control">
                        <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                            {{$t(selectedOption.name)}}
                        </button>
                        <div class="inner show">
                            <ul class="dropdown-menu">
                                <li v-for="option in options"
                                    class="dropdown-item pointer"
                                    :key="option.value"
                                    @click="setFrequency(option.value)"
                                    v-bind:class="{'active': option.value === selectedOption.value}">
                                    {{$t(option.name)}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="showMultiple" class="col-6 pr-0">
                    <label for="title">{{$t('Every')}}</label>
                    <input v-model="multiple" class="form-control" placeholder="" type="number" value="1" min="1">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        name: 'AppointmentOptionsFrequency',
        data() {
            return {
                options: [
                    { value: 0, name: 'No repeat' },
                    { value: 1, name: 'Week(s)' },
                    { value: 2, name: 'Month(s)' },
                    { value: 3, name: 'Year(s)' }
                ],
            }
        },
        computed: {
            ...mapState('AppointmentScheduleUpload', [
                'appointmentFrequencyId',
                'frequencyMultiple'
            ]),
            selectedOption() {
                const option = this.options.find(o => o.value === this.appointmentFrequencyId);
                return !!option ? option : this.options[0];
            },
            showMultiple() {
                return this.appointmentFrequencyId > 0;
            },
            multiple: {
                get() {
                    return this.frequencyMultiple.toString();
                },
                set(value) {
                    this.setMultiple(value);
                }
            }
        },
        watch: {
            appointmentFrequencyId(value) {
                //reset occurrences and frequencyMultiple to 1 if frequencyId is 0 or "No repeat"
                if (value === 0) {
                    this.setOccurrences(1);
                    this.setMultiple(1);
                }
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setFrequency',
                'setMultiple',
                'setOccurrences'
            ])
        }
    }
</script>