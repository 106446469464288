<template>
    <div>
        <button @click="handleRequestCard" style="width: max-content;" type="button" class="btn btn-sm btn-outline-secondary mr-2">
            <i class="fal fa-paper-plane"></i> {{$t('Request Card')}}
        </button>
        <button ref="displayModalButton" type="button" data-target="#request-card-modal" data-toggle="modal" style="display: none"></button>
        <modal-dialog id="request-card-modal" :title="$t('Card Details Request')">
            <section class="rounded-card-white mb-3">
                <div v-if="completed" class="alert alert-success alert-icon" role="alert">
                    <div class="alert-content">
                        <p>{{$t('Sent!')}}</p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="number">{{$t('Name')}}:</label>
                    </div>
                    <div class="col-md-8">{{clientName}}</div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="number">{{$t('Email')}}:</label>
                    </div>
                    <div class="col-md-8">
                        <span v-if="clientEmail">{{ clientEmail }}</span>
                        <label v-else-if="emailMessage" class="error my-0">{{ emailMessage }}</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="number">{{$t('Mobile tel')}}:</label>
                    </div>
                    <div class="col-md-8">
                        <span v-if="clientMobile">{{ clientMobile }}</span>
                        <label v-else-if="mobileMessage" class="error my-0">{{ mobileMessage }}</label>
                    </div>
                </div>
                <div class="form-group wide">
                    <label v-if="methodMessage" class="error">{{methodMessage}}</label>
                    <invite-send v-model="emailStatus" :customer="customer" :invite-id="inviteId" :send="emailSend" :method-type="1" @selected="emailSelected"></invite-send>
                    <invite-send v-model="smsStatus" :customer="customer" :invite-id="inviteId" :send="smsSend" :method-type="2" @selected="mobileSelected"></invite-send>
                </div>
            </section>
            <div class="w-100 d-flex justify-content-center">
                <button ref="cancelButton" type="button" class="btn btn-outline-secondary mr-2" data-dismiss="modal">{{$t('Cancel')}}</button>
                <button @click="handleProceed" v-bind:disabled="loading || disabled" type="button" class="btn btn-primary">
                    {{$t('Proceed')}}
                    <span v-if="loading" class="inline-loader"></span>
                </button>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
    import { CardDetailsInviteUploadMixin } from "../../Shared/CardDetailsInviteUploadMixin";
    import { CustomerCheckoutAddMixin } from "../../Shared/CustomerCheckoutAddMixin";
    import { ClientCacheMixin } from "../../Shared/ClientCacheMixin";
    import CardDetailsInviteCommunicationResend from "./CardDetailsInviteCommunicationResend.vue";
    import ModalDialog from "../../Layout/ModalDialog.vue";

    export default {
        name: 'PaymentInstrumentsProviderSumUpRequestButton',
        mixins: [CustomerCheckoutAddMixin, CardDetailsInviteUploadMixin, ClientCacheMixin],
        props: {
            customer: Object
        },
        data() {
            return {
                inviteUpdated: false,
                emailStatus: {},
                smsStatus: {},
                emailSend: 0,
                smsSend: 0,
                isEmail: false,
                isMobile: false,
                methodMessage: '',
                emailMessage: '',
                mobileMessage: ''
            }
        },
        computed: {
            clientId() {
                return !!this.customer ? this.customer.clientId : '';
            },
            clientName() {
                return !!this.client ? this.client.firstName + ' ' + this.client.lastName : 'Unknown';
            },
            clientEmail() {
                return !!this.client ? this.client.email : '';
            },
            clientMobile() {
                return !!this.client ? this.client.telephoneMobile : ''
            },
            providerTypeId() {
                return !!this.customer ? this.customer.providerType.id : 0;
            },
            customerId() {
                return !!this.customer ? this.customer.id : ''
            },
            reference() {
                return this.inviteId
            },
            // checkout reference returned by sumup api
            providerReference() {
                return this.addedCheckout ? this.addedCheckout.providerReference : ''
            },
            statuses() {
                return [this.emailStatus, this.smsStatus];
            },
            completed() {
                return !!this.inviteUpdated && this.statuses.every(s => s.completed);
            },
            loading() {
                return !!this.loadingInvite || !!this.addingCustomerCheckout || !!this.statuses.some(s => s.loading);
            },
            disabled() {
                return !this.clientEmail && !this.clientMobile;
            }
        },
        methods: {
            handleRequestCard() {
                this.addedCheckout = null;
                this.addedCardDetailsInvite = null;
                this.inviteUpdated = false;
                this.$refs.displayModalButton.click();
            },
            handleProceed() {
                this.checkMethod();
                //check no method selected
                if (!!this.methodMessage || !!this.emailMessage || !!this.mobileMessage) {
                    return;
                }
                if (!!this.addedCardDetailsInvite && !this.inviteUpdated) {
                    this.tryUploadCardDetailsInvite();
                } else if (!!this.inviteUpdated) {
                    this.trySendCommunications();
                } else if (!!this.addedCardDetailsInvite) {
                    this.tryAddCustomerCheckout();
                } else {
                    this.tryUploadCardDetailsInvite();
                }
            },
            afterAddCardDetailsInvite(invite) {
                this.addedCardDetailsInvite = invite;
                this.tryAddCustomerCheckout();
            },
            afterAddCustomerCheckout(checkout) {
                this.addedCheckout = checkout;
                this.tryUpdateCardDetailsInvite();
            },
            afterUpdateCardDetailsInvite(invite) {
                this.addedCardDetailsInvite = invite;
                this.inviteUpdated = true;
                this.trySendCommunications();
            },
            trySendCommunications() {
                if (!!this.inviteUpdated) {
                    if (!this.emailStatus.completed) {
                        this.emailSend++;
                    }
                    if (!this.smsStatus.completed) {
                        this.smsSend++;
                    }
                }
            },
            emailSelected(value) {
                this.isEmail = !!value
                this.emailMessage = !!this.isEmail && !this.clientEmail ? 'Client has no email set.' : '';
            },
            mobileSelected(value) {
                this.isMobile = !!value;
                this.mobileMessage = !!this.isMobile && !this.clientMobile ? 'Client has no telephone mobile set.' : '';
            },
            checkMethod() {
                this.methodMessage = this.isEmail || this.isMobile ? '' : 'Please select a method to send request.';
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'invite-send': CardDetailsInviteCommunicationResend
        }
    }
</script>
