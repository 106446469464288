<template>
    <div v-bind:class="{ 'resource-selected' : isSelected }"
         v-bind:style="{ borderColor: resourceColour, backgroundColor: isSelected ? resourceColour : '#ffffff' }"
         class="btn btn-outline-resource mb-2 resource-button"
         @mousedown="clicked = true"
         @mouseleave="clicked = false">
        <span class="resource-text text-truncate">{{resourceName}}</span>
        <i class="fal fa-sort resource-icon"></i>
    </div>
</template>

<script>
    import { ResourceCacheMixin } from "../../../components/Shared/Resources/ResourceCacheMixin";

    export default {
        name: 'SiteUpdateBookingResourceOrderItem',
        mixins: [ResourceCacheMixin],
        props: {
            resourceId: String,
            dragging: Boolean
        },
        data() {
            return {
                clicked: false
            }
        },
        computed: {
            isSelected() {
                return !!this.dragging || !!this.clicked
            },
            resource() {
                return this.resources.length > 0 ? this.resources.find(r => r.id === this.resourceId) : null;
            },
            resourceName() {
                return !!this.resource ? this.resource.name : 'Unknown';
            },
            resourceColour() {
                return !!this.resource ? this.resource.colour : '#ffffff';
            }
        }
    }
</script>

<style scoped>
    .resource-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0.5rem 1rem;
        margin-inline: auto;
    }
    .resource-text {
        flex-grow: 1;
        text-align: center;
    }
    .resource-icon {
        position: absolute;
        right: 1rem;
        color: black !important;
    }
    .resource-selected {
        box-shadow: 0 0.1rem 0.25rem rgba(0,0,0,.35);
    }
</style>
