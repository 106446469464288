import { ResourceCacheMixin } from "../../../components/Shared/Resources/ResourceCacheMixin";
import { SettingUploadMixin } from "../../../components/Shared/SettingUploadMixin";
import { SiteBookingResourceOrderSettingMixin } from "./SiteBookingResourceOrderSettingMixin";

export const SiteBookingResourceOrderSettingUploadMixin = {
    mixins: [SettingUploadMixin, SiteBookingResourceOrderSettingMixin, ResourceCacheMixin],
    data() {
        return {
            forNewClients: [],
            forRegisteredClients: []
        }
    },
    computed: {
        settingEditModel() {
            return {
                forNewClients: this.forNewClients,
                forRegisteredClients: this.forRegisteredClients
            }
        },
        settingEditModelValid() {
            const model = this.settingEditModel;
            return model.forNewClients.length > 0 && model.forRegisteredClients.length > 0;
        }
    },
    watch: {
        resources() {
            this.refreshSettingEditModel();
        }
    },
    methods: {
        refreshSettingEditModel() {
            const resourceIds = this.resources.map(r => r.id);
            this.forNewClients = [
                ...new Set([
                    ...this.settingObject.forNewClients,
                    ...resourceIds
                ])
            ].filter(id => resourceIds.includes(id));
            this.forRegisteredClients = [
                ...new Set([
                    ...this.settingObject.forRegisteredClients,
                    ...resourceIds
                ])
            ].filter(id => resourceIds.includes(id));
        }
    }
}
