import { PaymentApi } from "../../services/PaymentApi";

const state = () => ({
    fetchedPaymentInstrumentSettings: false,
    fetchingPaymentInstrumentSettings: false,
    paymentInstrumentSettings: null
})

const mutations = {
    setFetching(state, fetching) {
        state.fetchingPaymentInstrumentSettings = !!fetching;
        state.fetchedPaymentInstrumentSettings = false;
    },
    setPaymentInstrumentSettings(state, paymentInstrumentSettings) {
        state.paymentInstrumentSettings = paymentInstrumentSettings;
        state.fetchingPaymentInstrumentSettings = false;
        state.fetchedPaymentInstrumentSettings = true;
    }
}

const actions = {
    fetchPaymentInstrumentSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            PaymentApi.get('PaymentInstrument/Setting')
                .then(response => {
                    commit('setPaymentInstrumentSettings', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
