<template>
    <modal-dialog :title="$t('View Communication')" @closed="onClosed">
        <error-banner v-if="error" :message="error"></error-banner>
        <section class="rounded-card-white mb-3">
            <template>
                <slotted-input v-model="clientFullname" :title="$t('Sent To')"></slotted-input>
                <template v-if="isMethodSms">
                    <slotted-input v-model="communicationTypeDescription" :title="$t('Type')"></slotted-input>
                    <div v-if="loadingCommunicationContent" class="form-group row">
                        <div class="col-md-4"></div>
                        <div class="col-md-8 d-flex">
                            <span class="inline-loader"></span>
                            <label>{{$t('Loading message . . .')}}</label>
                        </div>
                    </div>
                    <slotted-text v-else v-model="content" :title="$t('Message')" :rows="10" :max-length="200"></slotted-text>
                </template>
                <div v-else class="alert alert-info alert-icon" role="alert">
                    <div class="alert-content">
                        <p>{{$t('This feature is only available for communications sent via SMS.')}}</p>
                    </div>
                </div>
            </template>
        </section>
    </modal-dialog>
</template>

<script>
    import ModalDialog from '../Layout/ModalDialog.vue';
    import SlottedInput from "../Shared/Input/SlottedInput.vue";
    import SlottedTextArea from "../Shared/Input/SlottedTextArea.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { CommunicationContentCacheMixin } from "../Shared/CommunicationContentCacheMixin";
    
    export default {
        name: 'CommunicationView',
        mixins: [CommunicationContentCacheMixin],
        props: {
            communication: Object,
            client: Object
        },
        computed: {
            isMethodSms() {
                return !!this.communication ? this.communication.methodType.id === 2 : false;
            },
            displayableCommunication() {
                return !!this.communication && !!this.isMethodSms ? this.communication : null;
            },
            communicationId() {
                return !!this.displayableCommunication ? this.communication.id : '';
            },
            communicationTypeDescription() {
                return !!this.displayableCommunication ? this.communication.communicationType.description : 'Unknown';
            },
            clientFullname() {
                return !!this.client ? this.client.firstName + ' ' + this.client.lastName : 'Unknown';
            }
        },
        methods: {
            onClosed() {
                this.$emit('closed');
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'error-banner': ErrorBanner,
            'slotted-text': SlottedTextArea,
            'slotted-input': SlottedInput
        }
    }
</script>
