import { CommunicationResendMixin } from "../../components/Shared/CommunicationResendMixin";
import { DateDisplayMixin } from "../../components/Shared/Time/DateDisplayMixin";
import { ClientOnboardingInviteAddMixin } from "./ClientOnboardingInviteAddMixin";
import moment from 'moment';

export const ClientOnboardingCommunicationMixin = {
    mixins: [ClientOnboardingInviteAddMixin, CommunicationResendMixin, DateDisplayMixin],
    props: {
        toggle: 0
    },
    data() {
        return {
            attempted: false
        }
    },
    computed: {
        subjectId() {
            return !!this.clientOnboardingInvite ? this.clientOnboardingInvite.id : '';
        },
        communicationTypeId() {
            return 11;
        },
        loading() {
            return !!this.loadingCommunications || !!this.loadingClientOnboardingInvite;
        },
        communicationStatus() {
            return !!this.communicationSuccessful ?
                moment(this.attemptedCommunications[0].lastUpdatedUtc).format(this.momentJSFormat) :
                this.$t('Not Sent');
        },
        clientOnboardingInviteCompleted() {
            return !!this.clientOnboardingInvite ? this.clientOnboardingInvite.completed : false;
        },
        onboardingInviteStatus() {
            return !!this.clientOnboardingInvite ? (this.clientOnboardingInvite.completed ? 'Completed' : this.clientOnboardingInvite.status.name) : 'Unknown';
        },
        failedCommunicationReason() {
            return !!this.failedCommunication ? this.failedCommunication.failureReason : ''
        },
        showFailureReason() {
            return !!this.failedCommunicationReason && !!this.attempted && !this.loadingCommunications;
        }
    },
    watch: {
        toggle() {
            if (!this.loadingCommunications) {
                this.attempted = false;
            }
        }
    },
    methods: {
        afterClientOnboardingInviteAdd() {
            this.tryUploadCommunication();
            this.attempted = true;
        }
    }
}
