<template>
    <div>
        <div class="title-divider mb-3 mt-0">
            <h2>{{$t('Pet')}} {{index + 1}} with {{resourceName}}</h2>
        </div>
        <div v-if="petDeleted" class="alert-wrapper px-0">
            <div role="alert" class="alert alert-icon alert-danger">
                <div class="alert-content">
                    <p>Pet does not exists.</p>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-4">
                <label for="name">{{$t('Name')}}:</label>
            </div>
            <div class="col-md-8">
                {{participantName}}
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-4">
                <label for="name">{{$t('Breed')}}:</label>
            </div>
            <div class="col-md-8">
                {{breedName}}
            </div>
        </div>
    </div>
</template>

<script>
    import { BreedCacheMixin } from "../Shared/BreedCacheMixin";
    import { ResourceCacheMixin } from "../Shared/Resources/ResourceCacheMixin";

    export default {
        name: 'BookingPetDetails',
        mixins: [BreedCacheMixin, ResourceCacheMixin],
        props: {
            participant: Object,
            clientPets: [],
            index: 0,
        },
        computed: {
            breedId() {
                return !!this.participant && !!this.participant.breedId ? this.participant.breedId : '';
            },
            participantName() {
                return !!this.participant ? this.participant.name : '';
            },
            pet() {
                return this.clientPets.length > 0 ? this.clientPets.find(p => p.id === this.participant.petId) : null;
            },
            petDeleted() {
                return !this.pet;
            },
            petNameChanged() {
                return !!this.pet ? ( this.pet.name === this.participantName ? false : true ) : false;
            },
            petBreedChanged() {
                return !!this.pet ? (this.pet.breedId === this.breedId ? false : true) : false;
            },
            resourceId() {
                return !!this.participant ? this.participant.resourceId : '';
            },
            resource() {
                return this.resources.length > 0 && !!this.participant ? this.resources.find(r => r.id === this.resourceId) : null;
            },
            resourceName() {
                return !!this.resource ? this.resource.name : '-';
            }
        }
    }
</script>
