<template>
    <div class="form-group row">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="col-md-4">
            <label>{{$t('Time Zone')}}</label>
        </div>
        <div class="col-md-8">
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{selectedOptionDescription}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu time-zone-dropdown-menu">
                        <li v-for="option in timeZoneOptions"
                            class="dropdown-item pointer"
                            :key="option.id"
                            @click="setTimeZoneId(option.id)"
                            v-bind:class="{'active': option.id === timeZoneId}">
                            {{option.description}}
                        </li>
                    </ul>
                </div>
            </div>
            <validation-message v-if="!!error" :message="error"></validation-message>
        </div>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from "../../../mixins/Shared/StatusEmitterMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import ValidationMessage from "../../ErrorValidation/ValidationMessage.vue";
    import { TimeZoneUpdateMixin } from "../../Shared/Time/Zone/TimeZoneUpdateMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'SiteUpdateLocalizationTimeZone',
        mixins: [TimeZoneUpdateMixin, StatusEmitterMixin],
        computed: {
            ...mapState('ApplicationEvents', [
                'saveSiteEvent'
            ]),
            status() {
                return {
                    loading: !!this.loadingTimeZone,
                    error: this.error
                }
            },
            selectedOption() {
                return this.timeZoneOptions.find(tzo => tzo.id == this.timeZoneId);
            },
            selectedOptionDescription() {
                return !!this.selectedOption ? this.selectedOption.description : `${this.$t('Please select')}...`;
            }
        },
        watch: {
            saveSiteEvent() {
                this.uploadAndCacheTimeZone();
            }
        },
        methods: {
            setTimeZoneId(value) {
                this.timeZoneId = value;
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
