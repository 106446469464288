import { mapActions } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { PaymentInstrumentsCacheMixin } from "./PaymentInstrumentsCacheMixin";

export const PaymentInstrumentsUpdateMixin = {
    mixins: [PaymentInstrumentsCacheMixin],
    watch: {
        customerId() {
            this.refreshInstruments();
        }
    },
    methods: {
        ...mapActions('PaymentInstruments', [
            'updateInstruments'
        ]),
        refreshInstruments() {
            if (!this.instrumentsCache || !!this.instrumentsCache.retry) {
                this.tryUpdatePaymentInstruments();
            }
        },
        tryUpdatePaymentInstruments() {
            const self = this;
            if (!self.customerId) {
                return;
            }
            self.error = ''
            self.updateInstruments(self.customerId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshInstruments();
    }
}
