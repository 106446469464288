<template>
    <div class="pricing-plan_card m-3" v-bind:style="{ 'background-color': colour }">
        <div class="d-flex flex-column align-items-center">
            <div class="pricing-plan_card-header p-4">
                <i class="fa-duotone fa-4x" v-bind:class="iconClass" v-bind:style="{ 'color': colour }"></i>
            </div>
            <div class="pricing-plan_card-body w-100 px-5 pt-2">
                <div class="mb-3">
                    <div class="pricing-plan_card-title pricing-plan_card-title-lg mt-0">{{ creditSetting.value }}</div>
                    <div class="pricing-plan_card-title pricing-plan_card-title-sub">{{$t(creditTypeDescription)}} Credits</div>
                </div>
                <div class="pricing-plan_card-money">
                    {{currencySymbol}}{{ price }}
                </div>
                <button @click="onClick" class="pricing-plan_card-body-button pricing-plan_card-body-button-primary w-100 mt-4">
                    <i class="fa-duotone fa-cart-shopping fa-1x"></i>
                    {{$t('Buy')}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { CountryCacheMixin } from "../Shared/Country/CountryCacheMixin";

    export default {
        name: 'CreditPackSelectItem',
        mixins: [CountryCacheMixin],
        props: {
            creditSetting: {
                type: Object,
                required: true
            },
            config: {
                type: Object,
                required: true
            }
        },
        computed: {
            iconClass() {
                return this.config.iconClass;
            },
            creditTypeDescription() {
                return this.config.creditTypeDescription;
            },
            currencySymbol() {
                return this.currency === 'GBP' ? '£' : '$';
            },
            settingId() {
                return this.creditSetting.id;
            },
            price() {
                const price = parseFloat(this.creditSetting.cost[this.currency]) || parseFloat(this.creditSetting.cost["USD"]);
                return isNaN(price) ? 0 : price;
            },
            colour() {
                return !!this.creditSetting ? this.creditSetting.colour : '#FFFFFF';
            }
        },
        methods: {
            onClick() {
                this.$emit('idSelected', this.settingId)
            }
        }
    }
</script>
