<template>
    <tbody>
        <tr v-if="showFailureReason">
            <td colspan="12">
                <error-banner class="mb-0" :message="failedCommunicationReason"></error-banner>
            </td>
        </tr>
        <tr v-if="!!zeroCredits">
            <td colspan="12">
                <div class="alert alert-danger alert-icon mb-0" role="alert">
                    <div class="alert-content">
                        <p>No SMS credits remaining. Click&nbsp;<a @click="gotoStoreSmsCredits">HERE</a> to top up.</p>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td class="col-3">{{$t('SMS')}}</td>
            <td class="col-3">
                <span v-if="loadingClientOnboardingInvite" class="inline-loader"></span>
                {{$t(onboardingInviteStatus)}}
            </td>
            <td class="col-3">
                <span v-if="loadingCommunications" class="inline-loader"></span>
                {{$t(communicationStatus)}}
            </td>
            <td class="col-3">
                <template v-if="!clientOnboardingInviteCompleted">
                    <button v-if="clientOnboardingInvite" style="width: max-content;" type="button" class="btn btn-sm btn-outline-secondary" @click="tryResend" v-bind:disabled="loading || zeroCredits">
                        <i class="fal fa-paper-plane"></i> {{$t('Resend')}}
                    </button>
                    <button v-else style="width: max-content;" type="button" class="btn btn-sm btn-outline-secondary" @click="trySend" v-bind:disabled="loading || zeroCredits">
                        <i class="fal fa-paper-plane"></i> {{$t('Send')}}
                    </button>
                </template>
            </td>
        </tr>
    </tbody>
</template>

<script>
    import { ClientOnboardingCommunicationMixin } from '../../mixins/ClientOnboardingInvites/ClientOnboardingCommunicationMixin.js';
    import { SmsSettingCacheMixin } from '../Communication/Settings/SmsSettingCacheMixin.js';
    import { ClientCacheMixin } from '../Shared/ClientCacheMixin.js';
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue'

    export default {
        name: 'ClientOnboardingInviteSms',
        mixins: [ClientOnboardingCommunicationMixin, ClientCacheMixin, SmsSettingCacheMixin],
        props: {
            id: String
        },
        computed: {
            clientId() {
                return !!this.id ? this.id : '';
            },
            methodTypeId() {
                return 2;
            },
            clientMobile() {
                return !!this.client ? this.client.telephoneMobile : '';
            },
            zeroCredits() {
                return !!this.smsSetting ? this.smsSetting.remainingCredits < 1 : false;
            }
        },
        methods: {
            tryResend() {
                if (!this.clientMobile) {
                    this.$emit('error', "Client has no mobile tel.");
                }
                else {
                    this.$emit('error', "");
                    this.tryUploadCommunication();
                    this.attempted = true;
                }
            },
            trySend() {
                if (!this.clientMobile) {
                    this.$emit('error', "Client has no mobile tel.");
                }
                else {
                    this.$emit('error', "");
                    this.tryAddClientOnboardingInvite();
                }
            },
            gotoStoreSmsCredits() {
                this.$router.push({ name: 'store', query: { tab: 0 } });
            }
        },
        components: {
            'error-banner' : ErrorBanner
        }
    }
</script>
