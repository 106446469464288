<template>
    <modal-dialog :title="titleString" @opened="onOpened">
        <error-banner v-if="error" :message="error"></error-banner>
        <resource-delete v-if="deleting" :id="id" @cancel="cancelDelete" @confirm="confirmDelete"></resource-delete>
        <section class="rounded-card-white mb-3">
            <name-input v-model="empName" :validator="userUpdateValidator"></name-input>
            <disable-toggle v-model="empIsDisabled"></disable-toggle>
            <div class="form-group row">
                <div class="col-md-4">
                    <label>{{$t('Email')}}</label>
                </div>
                <div class="col-md-8">
                    <input v-model="empEmail" class="form-control" disabled />
                </div>
            </div>
            <password-send v-if="employee" :user="employee" :email="empEmail"></password-send>
            <sites-select v-model="empSiteIds" :validator="userUpdateValidator"></sites-select>
            <admin-toggle v-model="employeeSiteAdmin"></admin-toggle>
            <can-delete-toggle v-model="employeeCanDelete"></can-delete-toggle>
            <read-only v-model="employeeReadOnly"></read-only>
            <payment-claims v-if="paymentApiUser" :employee-read-only="employeeReadOnly" :employee="employee" :site-ids="paymentApiUser.owner"></payment-claims>
        </section>
        <section class="rounded-card-white mb-3">
            <send-notification :user="employee"></send-notification>
        </section>
        <div class="modal-footer">
            <button @click="save" v-bind:disabled="updatingEmployee" class="btn btn-primary" type="button">
                {{$t('Save')}}
                <span v-if="updatingEmployee" class="inline-loader"></span>
            </button>
            <delete-button @clicked="deleting=true"></delete-button>
        </div>
        <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
    </modal-dialog>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    import ModalDialog from '../Layout/ModalDialog.vue';
    import DeleteButton from '../Shared/DeleteButton.vue';
    import { EmployeeUpdateMixin } from '../Shared/EmployeeUpdateMixin';
    import UserDisableToggle from './UserDisableToggle.vue';
    import UserNameInput from './UserNameInput.vue';
    import UserAdminToggle from "./UserAdminToggle.vue";
    import UserCanDeleteToggle from "./UserCanDeleteToggle.vue";
    import UserReadOnlyToggle from './UserReadOnlyToggle.vue'
    import UserPasswordSendButton from "./UserPasswordSendButton.vue";
    import UserSitesSelect from "./UserSitesSelect.vue";
    import NotificationSend from '../Shared/Notifications/NotificationSend.vue';
    import UserResourceDeleteFlow from './UserResourceDeleteFlow.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import { PaymentApiUserCacheMixin } from '../../mixins/Payments/PaymentApiUserCacheMixin';
    import UserPaymentClaimsUpdate from './UserPaymentClaimsUpdate.vue';

    export default {
        name: 'UserUpdate',
        mixins: [EmployeeUpdateMixin, PaymentApiUserCacheMixin],
        data() {
            return {
                deleting: false
            }
        },
        props: {
            id: String
        },
        computed: {
            employeeReadOnly: {
                get() {
                    return this.empIsReadOnly;
                },
                set(value) {
                    this.empIsReadOnly = !!value;
                    if (!!value) {
                        this.empIsSiteAdmin = !value;
                        this.empCanDelete = !value;
                    }
                }
            },
            employeeSiteAdmin: {
                get() {
                    return this.empIsSiteAdmin
                },
                set(value) {
                    this.empIsSiteAdmin = !!value;
                    if (!!value) {
                        this.empIsReadOnly = !value;
                    }
                }
            },
            employeeCanDelete: {
                get() {
                    return this.empCanDelete
                },
                set(value) {
                    this.empCanDelete = !!value;
                    if (!!value) {
                        this.empIsReadOnly = !value;
                    }
                }
            },
            ...mapState('ApplicationEvents', [
                'saveUserEvent',
                'userDeletedEvent'
            ]),
            employeeId() {
                return this.id;
            },
            empName: {
                get() {
                    return {
                        firstName: this.empFirstName,
                        lastName: this.empLastName
                    }
                },
                set(value) {
                    this.empFirstName = value.firstName;
                    this.empLastName = value.lastName;
                }
            },
            titleString() {
                return `${this.$t('Update')} ` + (!!this.employee ? this.employee.firstName + ' ' + this.employee.lastName : this.$t('Unknown'));
            }
        },
        watch: {
            saveUserEvent() {
                this.tryUpdateEmployee();
            },
            userDeletedEvent() {
                this.$refs.closeModal.click();
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerSaveUser'
            ]),
            save() {
                this.triggerSaveUser();
            },
            confirmDelete() {
                this.tryDeleteEmployee();
            },
            cancelDelete() {
                this.deleting = false;
            },
            onOpened() {
                this.deleting = false;
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'name-input': UserNameInput,
            'disable-toggle': UserDisableToggle,
            'sites-select': UserSitesSelect,
            'admin-toggle': UserAdminToggle,
            'password-send': UserPasswordSendButton,
            'can-delete-toggle': UserCanDeleteToggle,
            'read-only': UserReadOnlyToggle,
            'resource-delete': UserResourceDeleteFlow,
            'delete-button': DeleteButton,
            'send-notification': NotificationSend,
            'error-banner': ErrorBanner,
            'payment-claims': UserPaymentClaimsUpdate
        }
    }
</script>
