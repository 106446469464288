import { SiteIdMixin } from "../../../components/Shared/Site/SiteIdMixin";

export const SiteBookingResourceOrderSettingMixin = {
    mixins: [SiteIdMixin],
    computed: {
        ownerId() {
            return this.siteId;
        },
        ownerTypeId() {
            return 12;
        },
        settingKey() {
            return 'BookingResourceOrder';
        },
        defaultSettingObject() {
            return {
                forNewClients: [],
                forRegisteredClients: []
            }
        }
    }
}
