import Swal from 'sweetalert2';

export const showUnauthorizedAlert = () => {
    return Swal.fire({
        title: 'Unauthorized Access',
        html: '<div>You are not authorized to perform this action.</div><div>Please log in again.</div>',
        icon: 'warning',
        customClass: {
            confirmButton: 'btn btn-primary'
        }
    });
};
