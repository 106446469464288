<template>
    <div>
        <div class="d-flex flex-md-row flex-column justify-content-between">
            <div class="d-flex">
                <img alt="Default" :src="providerLogoUrl" height="20">
                <span class="ml-2">{{providerTypeName}}</span>
            </div>
            <div class="d-flex">
                <request-card :customer="customer"></request-card>
                <add-card :customer="customer" @process="onProcess"></add-card>
            </div>
        </div>
        <checkout-process :checkout="processing" :customer-id="customerId" @completed="onComplete" @cancelled="onCancel"></checkout-process>
    </div>
</template>

<script>
    import { PaymentProvidersCacheMixin } from "../../Payments/PaymentProvidersCacheMixin";
    import { PaymentInstrumentsUpdateMixin } from "../../Payments/PaymentInstrumentsUpdateMixin";
    import PaymentInstrumentsProviderSumUpRequestButton from "./PaymentInstrumentsProviderSumUpRequestButton.vue"
    import PaymentInstrumentsProviderSumUpAddButton from "./PaymentInstrumentsProviderSumUpAddButton.vue";
    import PaymentInstrumentsProviderCheckoutProcess from "./PaymentInstrumentsProviderCheckoutProcess.vue"

    export default {
        name: 'PaymentInstrumentsProviderSumUp',
        mixins: [PaymentProvidersCacheMixin, PaymentInstrumentsUpdateMixin],
        props: {
            customer: Object
        },
        data() {
            return {
                processing: null,
                error: ''
            }
        },
        computed: {
            customerId() {
                return !!this.customer ? this.customer.id : '';
            },
            providerType() {
                return !!this.customer ? this.customer.providerType : null;
            },
            providerTypeName() {
                return !!this.providerType ? this.providerType.name : '';
            },
            providerTypeId() {
                return !!this.providerType ? this.providerType.id : 0;
            },
            provider() {
                return this.paymentProviders.length > 0 ? this.paymentProviders.find(pp => pp.id === this.providerTypeId) : null
            },
            providerLogoUrl() {
                return !!this.provider ? this.provider.logoUrl : '';
            }
        },
        methods: {
            onProcess(checkout) {
                this.processing = checkout;
            },
            onComplete(message) {
                this.processing = null;
                this.error = !!message ? message : '';
                this.tryUpdatePaymentInstruments();
            },
            onCancel() {
                this.processing = null;
                this.error = '';
            }
        },
        components: {
            'request-card': PaymentInstrumentsProviderSumUpRequestButton,
            'add-card': PaymentInstrumentsProviderSumUpAddButton,
            'checkout-process': PaymentInstrumentsProviderCheckoutProcess
        }
    }
</script>
