<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Schedule Hours')}}</label>
        </div>
        <div class="col-md-8">
            <div class="mw-400">
                <div class="row">
                    <div class="col-6">
                        <label for="title">{{$t('Start')}}</label>
                        <vue-timepicker v-model="startHour" format="HH:mm" input-class="form-control caleran pointer w-100" auto-scroll></vue-timepicker>
                    </div>
                    <div class="col-6">
                        <label for="title">{{$t('End')}}</label>
                        <vue-timepicker v-model="endHour" format="HH:mm" input-class="form-control caleran pointer w-100" auto-scroll></vue-timepicker>
                    </div>
                </div>
            </div>
            <label v-if="message" class="error">{{ message }}</label>
        </div>
    </div>
</template>

<script>
    import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

    export default {
        name: 'ScheduleAppointmentHours',
        props: {
            value: Object,
            message: String
        },
        computed: {
            startHour: {
                get() {
                    return this.value.startHour;
                },
                set(value) {
                    this.$emit('input', {
                        startHour: value,
                        endHour: this.value.endHour
                    });
                }
            },
            endHour: {
                get() {
                    return this.value.endHour;
                },
                set(value) {
                    this.$emit('input', {
                        startHour: this.value.startHour,
                        endHour: value
                    });
                }
            }
        },
        components: {
            VueTimepicker
        }
    }
</script>

<style scoped>
    .vue__time-picker {
        width: 100% !important;
    }
</style>
