<template>
    <div>
        <site-select v-model="adminAccounts" :disable="employeeReadOnly" :siteIds="siteIds" title="Payment Admin"></site-select>
        <site-select v-model="refunderAccounts" :disable="employeeReadOnly" :siteIds="siteIds" title="Payment Refunds"></site-select>
    </div>
</template>

<script>
    import { PaymentUserAddMixin } from "../../mixins/Payments/PaymentUserAddMixin"
    import { mapState } from 'vuex';
    import PaymentSiteMultiSelect from "../Payments/PaymentSiteMultiSelect.vue";
    import { PaymentApiUserClaimAddMixin } from "../../mixins/Payments/PaymentApiUserClaimAddMixin";

    export default {
        name: 'UserPaymentClaimsAdd',
        mixins: [PaymentApiUserClaimAddMixin, PaymentUserAddMixin],
        data() {
            return {
                addedUser: null
            }
        },
        props: {
            siteIds: Array
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'userAddedEvent'
            ]),
            userId() {
                return !!this.addedUser ? this.addedUser.id : '';
            }
        },
        watch: {
            userAddedEvent(user) {
                this.addedUser = user;
                this.tryAddPaymentUserWithCompletion(this.tryAddPaymentApiUserClaims)
            }
        },
        components: {
            'site-select': PaymentSiteMultiSelect
        }
    }
</script>
