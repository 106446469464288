<template>
    <div>
        <button ref="displayModalButton" type="button" data-target="#checkout-modal" data-toggle="modal" style="display: none"></button>
        <modal-dialog id="checkout-modal" :title="$t('Card Details')" @closed="closed">
            <section class="rounded-card-white mb-3">
                <div class="d-flex flex-column align-items-center">
                    <sumup-widget v-if="providerTypeId == 1" class="mw-400" :checkout="checkout" @completed="onComplete" @errored="onComplete"></sumup-widget>
                </div>
            </section>
            <div class="d-flex justify-content-center align-items-center">
                <button ref="cancelButton" type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
    import ModalDialog from "../../Layout/ModalDialog.vue";
    import SumUpPaymentWidget from "../../Payments/SumUp/SumUpPaymentWidget.vue";

    export default {
        name: 'PaymentInstrumentsProviderCheckoutProcess',
        props: {
            checkout: Object,
            customerId: String
        },
        computed: {
            providerTypeId() {
                return !!this.checkout ? this.checkout.providerType.id : 0;
            }
        },
        watch: {
            checkout(value) {
                if (!!value) {
                    this.$refs.displayModalButton.click();
                }
            }
        },
        methods: {
            onComplete(message) {
                this.$emit('completed', message);
                this.$refs.cancelButton.click();
            },
            closed() {
                this.$emit('cancelled');
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'sumup-widget': SumUpPaymentWidget
        }
    }
</script>
