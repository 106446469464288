<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1>{{$t('My Account')}}</h1>
            </div>
            <div class="secondary-nav">
                <!--placeholder to occupy secondary nav area-->
            </div>
            <div class="header-tools">
                <!--placeholder to occupy header-tools area-->
            </div>
        </header>
        <div class="content d-flex flex-column flex-md-row">
            <div class="col-md-4">
                <user-options></user-options>
                <security-options></security-options>
                <system-admin-impersonate v-if="isSystemAdmin"></system-admin-impersonate>
                <system-options></system-options>
            </div>
            <div class="col-md-8">
                <collapsed-section id="user-resource" :title="$t('Schedule')">
                    <template #sectionIcon>
                        <i class="fas fa-calendar-alt mr-2"></i>
                    </template>
                    <user-resource></user-resource>
                </collapsed-section>
                <div class="d-flex flex-md-row flex-column" v-if="isSystemAdmin">
                    <div class="col-12 px-0 col-md-6 pr-md-2 pl-md-0">
                        <system-admin-import></system-admin-import>
                        <system-admin-account-access></system-admin-account-access>
                        <system-admin-general-notifications></system-admin-general-notifications>
                    </div>
                    <div class="col-12 px-0 col-md-6 pl-md-2 pr-md-0">
                        <system-admin-sms-credits></system-admin-sms-credits>
                        <system-admin-payment-transactions></system-admin-payment-transactions>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { UserCacheMixin } from "../Shared/UserCacheMixin";
    import UserOptions from "./UserOptions.vue";
    import SecurityOptions from "./SecurityOptions.vue";
    import SystemAdminImpersonate from "./SystemAdminImpersonate.vue";
    import SystemSettingsOptions from "./SystemSettingsOptions.vue";
    import SystemAdminImport from "./SystemAdminImport.vue";
    import SystemAdminSmsCredits from "./SystemAdminSmsCredits.vue";
    import SystemAdminGeneralNotifications from "./SystemAdminGeneralNotifications.vue";
    import SystemAdminAccountAccess from "./SystemAdminAccountAccess.vue";
    import SystemAdminPaymentTransactions from "./SystemAdminPaymentTransactions.vue";
    import UserResource from "./UserResource.vue";
    import CollapsedSection from "../Shared/Layout/CollapsedSection.vue";

    export default {
        name: 'MyAccount',
        mixins: [UserCacheMixin],
        components: {
            'user-options': UserOptions,
            'security-options': SecurityOptions,
            'system-admin-impersonate': SystemAdminImpersonate,
            'system-admin-import': SystemAdminImport,
            'system-admin-sms-credits': SystemAdminSmsCredits,
            'system-options': SystemSettingsOptions,
            'system-admin-general-notifications': SystemAdminGeneralNotifications,
            'system-admin-account-access': SystemAdminAccountAccess,
            'system-admin-payment-transactions': SystemAdminPaymentTransactions,
            'user-resource': UserResource,
            'collapsed-section': CollapsedSection
        }
    }
</script>
