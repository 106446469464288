<template>
    <div>
        <button @click="handleAddCard" style="width: max-content;" type="button" class="btn btn-sm btn-outline-secondary" v-bind:disabled="loading">
            <span v-if="loading" class="inline-loader"></span>
            <i v-else class="fal fa-plus"></i> {{$t('Add Card')}}
        </button>
    </div>
</template>

<script>
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import { CustomerCheckoutAddMixin } from "../../Shared/CustomerCheckoutAddMixin";
    import { CardDetailsInviteUploadMixin } from "../../Shared/CardDetailsInviteUploadMixin";

    export default {
        name: 'PaymentInstrumentsProviderSumUpAddButton',
        mixins: [CustomerCheckoutAddMixin, CardDetailsInviteUploadMixin],
        props: {
            customer: Object
        },
        data() {
            return {
                inviteUpdated: false
            }
        },
        computed: {
            providerTypeId() {
                return this.customer.providerType.id;
            },
            clientId() {
                return this.customer.clientId;
            },
            customerId() {
                return this.customer.id;
            },
            reference() {
                return this.inviteId
            },
            // checkout reference returned by sumup api
            providerReference() {
                return this.addedCheckout ? this.addedCheckout.providerReference : ''
            },
            loading() {
                return !!this.addingCustomerCheckout || !!this.loadingInvite
            }
        },
        watch: {
            error(value) {
                this.$emit('emitError', value)
            }
        },
        methods: {
            handleAddCard() {
                if (!!this.addedCardDetailsInvite && !this.inviteUpdated) {
                    this.tryUploadCardDetailsInvite();
                } else if (!!this.inviteUpdated) {
                    this.onProcess();
                } else if (!!this.addedCardDetailsInvite) {
                    this.tryAddCustomerCheckout();
                } else {
                    this.tryUploadCardDetailsInvite();
                }
            },
            afterAddCardDetailsInvite(invite) {
                this.addedCardDetailsInvite = invite;
                this.tryAddCustomerCheckout();
            },
            afterAddCustomerCheckout(checkout) {
                this.addedCheckout = checkout;
                this.tryUpdateCardDetailsInvite();
            },
            afterUpdateCardDetailsInvite(invite) {
                this.addedCardDetailsInvite = invite;
                this.inviteUpdated = true;
                this.onProcess();
            },
            onProcess() {
                this.$emit('process', this.addedCheckout);
                this.inviteUpdated = false;
                this.addedCheckout = null;
                this.addedCardDetailsInvite = null;
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
