import { ErrorHelper } from "../../helpers/ErrorHelper";
import { Api } from "../../services/Api";
import { CardDetailsInviteAddMixin } from "./CardDetailsInviteAddMixin";

export const CardDetailsInviteUploadMixin = {
    mixins: [CardDetailsInviteAddMixin],
    computed: {
        inviteId() {
            return this.addedCardDetailsInvite ? this.addedCardDetailsInvite.id : '';
        },
        providerReference() {
            console.log('OVERRIDE COMPUTED - providerReference');
        },
        cardDetailsInviteUpdateModel() {
            return {
                id: this.inviteId,
                clientId: this.clientId,
                providerTypeId: this.providerTypeId,
                providerReference: this.providerReference
            }
        },
        cardDetailsInviteUpdateModelValid() {
            const model = this.cardDetailsInviteUpdateModel
            return !!model.id && !!model.providerReference && !!model.providerTypeId
        }
    },
    methods: {
        tryUploadCardDetailsInvite() {
            if (!!this.addedCardDetailsInvite) {
                this.tryUpdateCardDetailsInvite();
            } else {
                this.tryAddCardDetailsInvite();
            }
        },
        tryUpdateCardDetailsInvite() {
            const self = this;
            if (!self.cardDetailsInviteUpdateModelValid) {
                return;
            }
            self.error = ''
            self.loadingInvite = true;
            Api.put('CardDetailsInvite', self.cardDetailsInviteUpdateModel)
                .then(response => { self.afterUpdateCardDetailsInvite(response.data) })
                .catch(error => ErrorHelper.getError(error))
                .finally(() => { self.loadingInvite = false; });
        },
        afterUpdateCardDetailsInvite() {
            //override if necessary
        }
    }
}