<template>
    <div class="calendar-staff-event">
        <div v-for="(line, index) in lines" :key="index">
            {{line}}
        </div>
        <event-notes v-if="showEventNotes" :event-id="staffEvent.id"></event-notes>
    </div>
</template>

<script>
    import { CalendarStaffEventSettingMixin } from "../../mixins/Settings/User/CalendarStaffEventSettingMixin";
    import { AllResourcesMixin } from "../Shared/Resources/AllResourcesMixin";
    import { UserSettingCacheMixin } from "../Shared/UserSettingCacheMixin";
    import CalendarStaffEventNotes from "./CalendarStaffEventNotes.vue";

    export default {
        name: 'CalendarStaffEvent',
        mixins: [AllResourcesMixin, UserSettingCacheMixin, CalendarStaffEventSettingMixin],
        props: {
            staffEvent: Object
        },
        computed: {
            eventId() {
                return this.staffEvent.id;
            },
            eventDescription() {
                return this.staffEvent.eventType.description;
            },
            resource() {
                return this.allResources.find(r => r.id === this.staffEvent.resourceId);
            },
            resourceName() {
                return !!this.resource ? this.resource.name : 'Unknown';
            },
            lines() {
                if (this.userSettingValue.displayType === 0) {
                    return [this.eventDescription + ' - ' + this.resourceName];
                } else if (this.userSettingValue.displayType === 1) {
                    return [this.eventDescription + ' ' + ' (' + this.resourceName + ')'];
                }
                return [this.eventDescription + ' - ' + this.resourceName];
            },
            showEventNotes() {
                return this.userSettingValue.displayType === 1;
            }
        },
        methods: {
            tryGetResources() {
                // do nothing
            }
        },
        components: {
            'event-notes': CalendarStaffEventNotes
        }
    }
</script>
