<template>
    <div class="d-flex flex-column align-items-center">
        <error-banner v-if="error" :message="error"></error-banner>
        <error-banner v-if="message" :message="message" class="mb-1"></error-banner>
        <div v-if="showCloseAccountBanner" class="alert alert-info alert-icon" role="alert">
            <div class="alert-content">
                <div class="alert-content d-flex align-items-center">
                    <p>Would you like to <b>close</b> this account instead? If so, please click</p>
                    <a class="ml-1" @click="showConfirmationModal">{{$t('HERE')}}</a>.
                </div>
            </div>
        </div>
        <div class="h2">{{$t('Pricing Plan')}}</div>
        <div class="text-center mb-0"><strong>{{$t('Our Subscriptions')}}</strong>{{$t(', simple, flexible and designed for your business needs.')}}</div>
        <div class="text-center mb-2">{{$t('Save 10%')}} <strong>10%</strong>{{$t(', by choosing our yearly subscription.')}}</div>
        <div class="form-group">
            <label class="ps-toggle-switch">
                <span class="d-inline-block mr-2">{{$t('Monthly')}}</span>
                <input v-model="yearly" class="toggle-checkbox toggle-subscription-yearly" type="checkbox" v-bind:disabled="disableYearly">
                <div class="toggle-switch"></div>
                <span class="d-inline-block ml-2">{{$t('Yearly')}}</span>
            </label>
        </div>
        <div class="flex-wrap flex-md-row d-flex flex-column justify-content-center align-items-center align-items-md-start w-100 mt-2">
            <div v-for="type in subscriptionUpgradeTypes">
                <upgrade-type :subscription-type="type" :selected-upgrade="subscriptionUpgradeAddModel" @upgradeSelected="onUpgradeSelected"></upgrade-type>
            </div>
        </div>
        <disable-account-confirmation ref="disableAccountModal"></disable-account-confirmation>
    </div>
</template>

<script>
    import { SubscriptionCacheMixin } from "../../mixins/Subscriptions/SubscriptionCacheMixin";
    import { SubscriptionUpgradeCheckAllowanceMixin } from "../../mixins/Subscriptions/SubscriptionUpgradeCheckAllowanceMixin";
    import { SubscriptionUpgradeTypesCacheMixin } from "../../mixins/Subscriptions/SubscriptionUpgradeTypesCacheMixin";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import SubscriptionUpgradeType from "./SubscriptionUpgradeType.vue";
    import DisableAccountConfirmation from "../Account/DisableAccountConfirmation.vue"

    export default {
        name: 'SubscriptionUpgradeSelect',
        mixins: [SubscriptionUpgradeTypesCacheMixin, SubscriptionCacheMixin, SubscriptionUpgradeCheckAllowanceMixin],
        watch: {
            subscription() {
                this.refreshYearly();
            }
        },
        computed: {
            disableYearly() {
                return !!this.subscription ? !!this.subscription.yearly : false;
            }
        },
        methods: {
            refreshYearly() {
                this.yearly = !!this.subscription ? this.subscription.yearly : false;
            },
            onUpgradeSelected(value) {
                this.subscriptionTypeId = value.subscriptionTypeId;
                this.additionalSites = value.additionalSites;
                this.tryCheckAllowance();
            },
            afterCheckingAllowance() {
                if (!!this.upgradeAllowed) {
                    this.tryAddSubscriptionUpgrade();
                }
            },
            showConfirmationModal() {
                this.$refs.disableAccountModal.$refs.displayModalButton.click();
            }
        },
        components: {
            'upgrade-type': SubscriptionUpgradeType,
            'error-banner': ErrorBanner,
            'disable-account-confirmation': DisableAccountConfirmation
        },
        created() {
            this.refreshYearly();
        }
    }
</script>

<style scoped>
    .ps-toggle-switch .toggle-subscription-yearly[disabled] + .toggle-switch{
        opacity: 1;
    }
</style>
